import {HttpbaseService} from './httpbase.service';
import {HttpClient} from '@angular/common/http';
import {EnvService} from './env.service';
import {InsuranceCompany} from '../data/insuranceCompany';
import {Observable, of} from 'rxjs';
import {Injectable} from '@angular/core';
import {CasedataModel} from '../data/casedata.model';
import {TranslateService} from '@ngx-translate/core';
import {map} from 'rxjs/operators';
import {CaseDataDto} from '../data/casedata.dto';
import {incidentCauseCodeHasInquiryType} from '../components/shared/utils/cause-code-helper';
import {LanguageService} from 'sos-common-ui';
import {CaseOptionsResourceService} from '../shared/api/services/case-options-resource.service';
import {CustomerProductTypeEnum} from '../data/customerProductTypeEnum';
import {AssistanceTypeEnum} from '../data/assistanceTypeEnum';

@Injectable({
  providedIn: 'root'
})
export class CasecreateService extends HttpbaseService {

  private insuranceMap: Map<string, InsuranceCompany[]>;
  private readonly PROP_CREDIT_CARD = 'creditCard';
  private readonly PROP_CREDIT_CARD_COMPANY = 'creditCardCompany';
  private readonly PROP_CASE_DESCRIPTION = 'caseDescription';
  private readonly PROP_DIAGNOSES_NAME = 'diagnosesName';
  private readonly PROP_CHILD = 'child';
  private readonly keysNotToMap: string[] = [this.PROP_CREDIT_CARD_COMPANY, this.PROP_CREDIT_CARD, this.PROP_DIAGNOSES_NAME];

  constructor(private httpClient: HttpClient,
              private env: EnvService,
              private translateService: TranslateService,
              private languageService: LanguageService,
              private caseOptionsResourceService: CaseOptionsResourceService) {
    super();
    this.insuranceMap = new Map<string, InsuranceCompany[]>();
  }

  getInsuranceCompanies(country: string): Observable<InsuranceCompany[]> {
    if (this.insuranceMap.has(country)) {
      return of(this.insuranceMap.get(country));
    } else {
      return this.caseOptionsResourceService.caseoptionsV1InsuranceCountryGet({country, application: EnvService.PROP_APP_NAME}).pipe(
        map(items => {
          items = items.sort((a, b) =>
            a.customerProfileName.localeCompare(b.customerProfileName, this.languageService.getLanguageCode(country)));

          const convertToModelInsuranceCompany: InsuranceCompany[] =
            items.map(x => new InsuranceCompany(x.customerProfileName, x.profileCode, CustomerProductTypeEnum[x.customerProductType],
              x.customerProductRelationId, x.customerProductRelationBank, x.profileId));

          this.insuranceMap.set(country, convertToModelInsuranceCompany);
          return this.insuranceMap.get(country);
        })
      );
    }
  }

  onSubmitCase(casedataModel: CasedataModel): Observable<string> {
    const inquiryType = casedataModel.inquiryType;
    if (inquiryType !== undefined && incidentCauseCodeHasInquiryType(casedataModel.incidentCauseCode)) {
      casedataModel.caseDescription[casedataModel.incidentCauseCode] =
        `${casedataModel.diagnosesName} \n ${casedataModel.caseDescription[casedataModel.incidentCauseCode]}`;
    }
    let caseDataDto: CaseDataDto = new CaseDataDto();
    this.mapCaseDataModelToCaseDataDTO(casedataModel, caseDataDto);
    return this.httpClient.post(`api/case/v1/create`, caseDataDto, { responseType: 'text' });
  }

  private mapCaseDataModelToCaseDataDTO(caseDataModel: CasedataModel, caseDataDto: CaseDataDto): CaseDataDto {
    Object.entries(caseDataModel).forEach(([key, value]) => {
      if (key === this.PROP_CASE_DESCRIPTION) {
        caseDataDto[key] = caseDataModel[key][caseDataModel.incidentCauseCode];
      } else if (this.keysNotToMap.includes(key)) {
        // Do not map these properties
      } else if ((key === this.PROP_CHILD) && caseDataModel.assistanceType === AssistanceTypeEnum.ADULT) {
          // ignore child mapping, if adult is chosen
      } else {
        caseDataDto[key] = value;
      }
    });
    if (caseDataModel.creditCard) {
      caseDataDto.insuranceCompany = caseDataModel.creditCardCompany;
    }
    return caseDataDto;
  }
}
