import {CountryEnum} from './countryEnum';
import {ValidatorFn, Validators} from '@angular/forms';

export class Nationality {

  public static readonly NATIONALITIES: Nationality[] = [
    new Nationality(CountryEnum.DK, '00 00 00 00', '+45', '000000-0000',
      'general.cprhint-DK', CountryEnum.DK, Validators.pattern(
        '^[0-3][0-9][0-1][0-9]{3}[-][0-9]{4}$')),
    new Nationality(CountryEnum.NO, '000 00 000', '+47', '00000000000',
      'general.cprhint-NO', CountryEnum.NO, Validators.pattern(
        '^[0-34-7][0-9][0-14-5][0-9]{8}$')),
    new Nationality(CountryEnum.SE, '000 000 000', '+46', '000000T0000',
      'general.cprhint-SE', CountryEnum.SE, Validators.pattern(
        '^[0-9]{2}[0-1][0-9][0-36-9][0-9][-+][0-9]{4}$')),
    new Nationality(CountryEnum.FI, '00 000 0000', '+358', '000000T000A',
      'general.cprhint-FI', CountryEnum.FI, Validators.pattern(
        '^[0-3][0-9][0-1][0-9]{3}[-aA][0-9]{3}[0-9a-fA-FhHj-nJ-NpPr-yR-Y]')),
    new Nationality(CountryEnum.IS, '00 00 00 00', '+354', '0000000000',
      'general.cprhint-IS', CountryEnum.IS, Validators.pattern(
        '^[0-3][0-9][0-1][0-9]{3}[0-9]{4}$')),
    new Nationality(CountryEnum.FO, '00 00 00 00', '+298', '000000000',
      'general.cprhint-FO', CountryEnum.FO, Validators.pattern('^[0-3][0-9][0-1][0-9]{3}[0-9]{3}$'))
  ];


  countryCode: CountryEnum;
  phoneMask: string;
  phoneCode: string;
  ssnMask: string;
  ssnPlaceHolder: string;
  flagIcon: string;
  validatorPattern: ValidatorFn;

  constructor(countryCode: CountryEnum, phoneMask: string, phoneCode: string,
              ssnMask: string, ssnPlaceHolder: string, flagIcon: string,
              validatorPattern: ValidatorFn) {
    this.countryCode = countryCode;
    this.phoneMask = phoneMask;
    this.phoneCode = phoneCode;
    this.ssnMask = ssnMask;
    this.ssnPlaceHolder = ssnPlaceHolder;
    this.flagIcon = flagIcon;
    this.validatorPattern = validatorPattern;
  }

  public static byCountry(countryEnum: CountryEnum): Nationality {
    return this.NATIONALITIES.find(n => n.countryCode === countryEnum);
  }

  public static getCountrySSNValidator(nationality: Nationality): ValidatorFn {
    if (!Object.values(CountryEnum).includes(nationality.countryCode)) {
      throw new Error('Wrong nationality countryCode: ' + nationality.countryCode);
    }
    return this.NATIONALITIES.find(n => n.countryCode === nationality.countryCode).validatorPattern;
  }
}
