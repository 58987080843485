/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { StrictHttpResponse } from '../../strict-http-response';
import { RequestBuilder } from '../../request-builder';

import { Question } from '../../models/question';

export interface V1QuestionInsuranceCountryCodeLanguageGet$Params {
  insuranceCountryCode: string;
  language: string;
  application?: string;
}

export function v1QuestionInsuranceCountryCodeLanguageGet(http: HttpClient, rootUrl: string, params: V1QuestionInsuranceCountryCodeLanguageGet$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<Question>>> {
  const rb = new RequestBuilder(rootUrl, v1QuestionInsuranceCountryCodeLanguageGet.PATH, 'get');
  if (params) {
    rb.path('insuranceCountryCode', params.insuranceCountryCode, {});
    rb.path('language', params.language, {});
    rb.header('application', params.application, {});
  }

  return http.request(
    rb.build({ responseType: 'json', accept: 'application/json', context })
  ).pipe(
    filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
    map((r: HttpResponse<any>) => {
      return r as StrictHttpResponse<Array<Question>>;
    })
  );
}

v1QuestionInsuranceCountryCodeLanguageGet.PATH = '/v1/question/{insuranceCountryCode}/{language}';
