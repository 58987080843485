export class EventDate {

  public static readonly eventDates: EventDate[] = [
    new EventDate('0', 'today', false),
    new EventDate('1', 'yesterday', false),
    new EventDate('2', 'anotherDay', false),
  ];
  id: string;
  value: string;
  selected: any;

  constructor(id: string, value: string, selected: any) {
    this.id = id;
    this.value = value;
    this.selected = selected;
  }
}
