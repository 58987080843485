/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { StrictHttpResponse } from '../../strict-http-response';
import { RequestBuilder } from '../../request-builder';

import { InsuranceCompanyWithCountry } from '../../models/insurance-company-with-country';

export interface CaseoptionsV1InsuranceCollectionsNordicGet$Params {
  application?: string;
}

export function caseoptionsV1InsuranceCollectionsNordicGet(http: HttpClient, rootUrl: string, params?: CaseoptionsV1InsuranceCollectionsNordicGet$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<InsuranceCompanyWithCountry>>> {
  const rb = new RequestBuilder(rootUrl, caseoptionsV1InsuranceCollectionsNordicGet.PATH, 'get');
  if (params) {
    rb.header('application', params.application, {});
  }

  return http.request(
    rb.build({ responseType: 'json', accept: 'application/json', context })
  ).pipe(
    filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
    map((r: HttpResponse<any>) => {
      return r as StrictHttpResponse<Array<InsuranceCompanyWithCountry>>;
    })
  );
}

caseoptionsV1InsuranceCollectionsNordicGet.PATH = '/caseoptions/v1/insurance/collections/nordic';
