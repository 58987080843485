import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import {HttpbaseService} from './httpbase.service';
import {EnvService} from './env.service';
import {TimeZoneModel} from '../data/timeZoneModel';
import {MapsAddress} from '../data/maps-address';
import GeocoderAddressComponent = google.maps.GeocoderAddressComponent;
import GeocoderResponse = google.maps.GeocoderResponse;
import PlaceResult = google.maps.places.PlaceResult;
import GeocoderResult = google.maps.GeocoderResult;
import PlaceGeometry = google.maps.places.PlaceGeometry;
import GeocoderGeometry = google.maps.GeocoderGeometry;
import {GoogleGeocoderService} from './google-geocoder-service';
import {PlacesResourceService} from '../shared/api/services/places-resource.service';

@Injectable({
  providedIn: 'root'
})
export class GeolocationService extends HttpbaseService {

  GEOLOCATE_URL = 'https://www.googleapis.com/geolocation/v1/geolocate';
  MAPS_API_URL = 'https://maps.googleapis.com/maps/api/';

  constructor(private httpClient: HttpClient,
              private env: EnvService,
              private googleGeoCodeService: GoogleGeocoderService,
              private placesResourceService: PlacesResourceService) {
    super();
  }

  locations = new Observable<{lat: number|string, long: number|string}>((obs) => {
    this.initialize(data => {
      if (data.error !== undefined) {
        obs.error(data.error);
      } else {
        obs.next({lat: data.lat, long: data.long});
        obs.complete();
      }
    });
  });

  private initialize(callback): void {
    navigator?.geolocation?.getCurrentPosition((successCallback) => {
      callback({lat: successCallback.coords.latitude, long: successCallback.coords.longitude});
    }, (errorCallback) => {
      this.getLatLongUsingGoogleAPI(callback);
    }, {enableHighAccuracy: true, maximumAge: 0, timeout: 10000});
  }

  private getLatLongUsingGoogleAPI(callback): void {
    this.httpClient.post<any>(`${this.GEOLOCATE_URL}?key=${this.env.GOOGLE_API_KEY}`, '').subscribe(resp => {
      callback({lat: resp.location.lat, long: resp.location.lng});
    }, error => {
      callback({error: JSON.stringify(error)});
    });
  }

  getAddressByLatLong(callback: (addressList: GeocoderResponse) => void, lat, lng): void {
    this.googleGeoCodeService.geocode({location: {lat, lng}}).then(value => {
      if (value.results.length > 1) {
        value.results = value.results.filter(entry =>
          // If address_components row entry has a plus_code type in their element list it gets removed from address list
          entry.address_components.every(element => element.types.find(type => type !== 'plus_code'))
        );
      }
      callback(value);
    });
  }

  getCurrentTimeZoneDataByLatLong(lat: number, lng: number, callback: (tz: TimeZoneModel|Error) => void): void {
    const timestamp = Math.floor(Date.now());
    const tz = new TimeZoneModel();
    this.placesResourceService.locationV1TimezoneLatLngDateGet({date: timestamp, lat, lng})
      .subscribe({ next: resp => {
        tz.dstOffset = resp.dstOffset;
        tz.rawOffset = resp.rawOffset;
        tz.timeZoneName = resp.timeZoneName;
        tz.timeZoneId = resp.timeZoneId;
        callback(tz);
      }, error: err => {
        callback(err);
        }});
  }

  getComposedMapsAddress(
    addressComponents: {address_components: google.maps.GeocoderAddressComponent[]}[]
  ): MapsAddress {
    let country: string;
    let streetNumber: string;
    let streetName: string;
    let zipCode: string;
    let city: string;
    let alpha2Code: string;
    for (const addressComponent of addressComponents){
        const prospectiveAddress = this.parseAddress(addressComponent.address_components);
        if (!country && prospectiveAddress.country){
          country = prospectiveAddress.country;
        }
        if (!streetNumber && prospectiveAddress.streetNumber){
          streetNumber = prospectiveAddress.streetNumber;
        }
        if (!streetName && prospectiveAddress.streetName){
          streetName = prospectiveAddress.streetName;
        }
        if (!zipCode && prospectiveAddress.zipCode){
          zipCode = prospectiveAddress.zipCode;
        }
        if (!city && prospectiveAddress.city){
          city = prospectiveAddress.city;
        }
        if (!alpha2Code && prospectiveAddress.alpha2Code){
          alpha2Code = prospectiveAddress.alpha2Code;
        }
    }
    return {
      alpha2Code,
      country,
      streetNumber,
      streetName,
      zipCode,
      city
    } as MapsAddress;
  }

  parseAddress(addressComponents: GeocoderAddressComponent[]): MapsAddress{
    const country = this.findPropertyLongName(addressComponents, 'country');
    const streetNumber = this.findPropertyLongName(addressComponents, 'street_number');
    const streetName = this.findPropertyLongName(addressComponents, 'route');
    const zipCode = this.findPropertyLongName(addressComponents, 'postal_code');
    const city = this.findPropertyLongName(addressComponents, 'locality');
    const administrativeArea = this.findPropertyLongName(addressComponents, 'administrative_area_level_3');
    const alpha2Code = this.findPropertyShortName(addressComponents, 'country');
    return {alpha2Code, country, streetNumber, streetName, zipCode, city: city ? city : administrativeArea};
  }

  placeToGeolocation(placeResult: PlaceResult): GeocoderResult{
    return {
      formatted_address: placeResult.formatted_address,
      address_components: placeResult.address_components,
      place_id: placeResult.place_id,
      geometry: this.placeGeoGeometryToGeoGeometry(placeResult.geometry),
      types: placeResult.types,
      partial_match: false,
      plus_code: placeResult.plus_code,
      postcode_localities: []
    };
  }

  geoLocationToPlace(geocoderResult: GeocoderResult): PlaceResult {
    return {
      place_id: geocoderResult.place_id,
      geometry: this.geoGeometryToPlaceGeometry(geocoderResult.geometry),
      types: geocoderResult.types,
      plus_code: geocoderResult.plus_code,
      address_components: geocoderResult.address_components,
      formatted_address: geocoderResult.formatted_address
    };
  }

  placeGeoGeometryToGeoGeometry(placeGeometry: PlaceGeometry): GeocoderGeometry {
    return {
      location: placeGeometry.location,
      bounds: undefined,
      location_type: undefined,
      viewport: placeGeometry.viewport
    };
  }

  geoGeometryToPlaceGeometry(geoGeometry: GeocoderGeometry): PlaceGeometry{
    return {
      location: geoGeometry.location,
      viewport: geoGeometry.viewport
    };
  }



  hasPropertyType(object, property: string): boolean {
    return object.types.find(type => type === property) !== undefined;
  }

  private findProperty(addressComponents, property: string): any {
    return addressComponents.find(element => element.types.find(type => type === property));
  }

  private findPropertyLongName(addressComponents, property: string): string {
    const field = this.findProperty(addressComponents, property);
    return field !== undefined ? (field.long_name ? field.long_name : field.short_name) : null;
  }

  private findPropertyShortName(addressComponents, property: string): string {
    const field = this.findProperty(addressComponents, property);
    return field !== undefined ? field.short_name : null;
  }

}
