/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { AutocompleteStructuredFormatting } from '../models/autocomplete-structured-formatting';
import { locationV1PlacesQueryGet } from '../fn/places-resource/location-v-1-places-query-get';
import { LocationV1PlacesQueryGet$Params } from '../fn/places-resource/location-v-1-places-query-get';
import { locationV1TimezoneLatLngDateGet } from '../fn/places-resource/location-v-1-timezone-lat-lng-date-get';
import { LocationV1TimezoneLatLngDateGet$Params } from '../fn/places-resource/location-v-1-timezone-lat-lng-date-get';
import { MatchedSubstring } from '../models/matched-substring';
import { Term } from '../models/term';
import { TimeZoneModel } from '../models/time-zone-model';

@Injectable({ providedIn: 'root' })
export class PlacesResourceService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `locationV1PlacesQueryGet()` */
  static readonly LocationV1PlacesQueryGetPath = '/location/v1/places/{query}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `locationV1PlacesQueryGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  locationV1PlacesQueryGet$Response(params: LocationV1PlacesQueryGet$Params, context?: HttpContext): Observable<StrictHttpResponse<{
'description'?: string;
'placeId'?: string;
'types'?: Array<string>;
'terms'?: Array<Term>;
'distanceMeters'?: number;
'matchedSubstrings'?: Array<MatchedSubstring>;
'structuredFormatting'?: AutocompleteStructuredFormatting;
}>> {
    return locationV1PlacesQueryGet(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `locationV1PlacesQueryGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  locationV1PlacesQueryGet(params: LocationV1PlacesQueryGet$Params, context?: HttpContext): Observable<{
'description'?: string;
'placeId'?: string;
'types'?: Array<string>;
'terms'?: Array<Term>;
'distanceMeters'?: number;
'matchedSubstrings'?: Array<MatchedSubstring>;
'structuredFormatting'?: AutocompleteStructuredFormatting;
}> {
    return this.locationV1PlacesQueryGet$Response(params, context).pipe(
      map((r: StrictHttpResponse<{
'description'?: string;
'placeId'?: string;
'types'?: Array<string>;
'terms'?: Array<Term>;
'distanceMeters'?: number;
'matchedSubstrings'?: Array<MatchedSubstring>;
'structuredFormatting'?: AutocompleteStructuredFormatting;
}>): {
'description'?: string;
'placeId'?: string;
'types'?: Array<string>;
'terms'?: Array<Term>;
'distanceMeters'?: number;
'matchedSubstrings'?: Array<MatchedSubstring>;
'structuredFormatting'?: AutocompleteStructuredFormatting;
} => r.body)
    );
  }

  /** Path part for operation `locationV1TimezoneLatLngDateGet()` */
  static readonly LocationV1TimezoneLatLngDateGetPath = '/location/v1/timezone/{lat}/{lng}/{date}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `locationV1TimezoneLatLngDateGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  locationV1TimezoneLatLngDateGet$Response(params: LocationV1TimezoneLatLngDateGet$Params, context?: HttpContext): Observable<StrictHttpResponse<TimeZoneModel>> {
    return locationV1TimezoneLatLngDateGet(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `locationV1TimezoneLatLngDateGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  locationV1TimezoneLatLngDateGet(params: LocationV1TimezoneLatLngDateGet$Params, context?: HttpContext): Observable<TimeZoneModel> {
    return this.locationV1TimezoneLatLngDateGet$Response(params, context).pipe(
      map((r: StrictHttpResponse<TimeZoneModel>): TimeZoneModel => r.body)
    );
  }

}
