import {Component, OnInit} from '@angular/core';
import {UntypedFormControl, Validators} from '@angular/forms';
import {CaseDataService} from '../../services/case-data.service';
import {AssistanceTypeEnum} from '../../data/assistanceTypeEnum';
import {TranslateService} from '@ngx-translate/core';
import {FooterService} from 'sos-common-ui';


@Component({
  selector: 'app-child-option',
  templateUrl: './child-option.component.html'
})
export class ChildOptionComponent implements OnInit {

  assistanceTypeEnum: typeof AssistanceTypeEnum = AssistanceTypeEnum;
  assistanceTypes: AssistanceTypeEnum[] = new Array();
  assistanceType = new UntypedFormControl(this.caseDataService.getCasedata().assistanceType, [Validators.required]);
  dataTestId = 'who-needs-help';

  constructor(private caseDataService: CaseDataService,
              private translate: TranslateService,
              private footerService: FooterService) {
  }

  ngOnInit(): void {
    // tslint:disable-next-line:no-console
    console.info('/child-option page');
    this.assistanceTypes = [AssistanceTypeEnum.ADULT, AssistanceTypeEnum.CHILD];
    this.footerService.setNavigationMode({
      enableNavForward: this.enableForward.bind(this),
      navLinkForward: this.getLink.bind(this), onNextFunction: this.save.bind(this)
    });
  }

  save(): void {
    this.caseDataService.save({assistanceType: this.assistanceType.value});
  }

  enableForward(): boolean {
    return this.assistanceType?.valid;
  }

  getLink(): string {
    return this.assistanceType.value === this.assistanceTypeEnum.CHILD ? '/child-info' : '/option';
  }
}
