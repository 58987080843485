import {Component, NgZone, OnInit} from '@angular/core';
import {CaseDataService} from '../../services/case-data.service';
import {GeolocationService} from '../../services/geolocation.service';
import {AddressModel} from '../../data/address.model';
import {AbstractControl, FormControl, FormGroup, ValidationErrors, Validators} from '@angular/forms';
import {MapsAddress} from '../../data/maps-address';
import PlaceResult = google.maps.places.PlaceResult;
import {FooterService} from 'sos-common-ui';
import AutocompleteOptions = google.maps.places.AutocompleteOptions;
import {retrieveLat, retrieveLng} from '../shared/utils/google-utils';
import {ModifiedPlaceResult} from '../shared/ModifiedPlaceResult';
import {isTimeZoneModel, TimeZoneModel} from '../../data/timeZoneModel';

@Component({
  selector: 'app-other-location',
  templateUrl: './other-location.component.html'
})
export class OtherLocationComponent implements OnInit {
  private readonly KEY_LAST_SELECTED_ADDRESS = 'lastSelectedAddress';
  options: AutocompleteOptions = {
     // types: ['(cities)']
  };

  otherLocationForm = new FormGroup({
    location: new FormControl<PlaceResult>(null, [Validators.required, this.timeZoneValidator.bind(this)])
  });
  timezone: TimeZoneModel|null;
  dataTestId = 'place-of-incident';

  constructor(private geolocationService: GeolocationService,
              private caseDataService: CaseDataService,
              private footerService: FooterService,
              private zone: NgZone
      ) {
  }

  ngOnInit(): void {
    // tslint:disable-next-line:no-console
    console.info('/other-location page');
    const incidentAddress = this.caseDataService.getCasedata().addressIncident;
    if (incidentAddress.addressIncident) {
      const sessionAddress = this.caseDataService.getSessionData(this.KEY_LAST_SELECTED_ADDRESS);
      if (sessionAddress !== undefined && sessionAddress !== null) {
        this.otherLocationForm.controls.location.setValue(JSON.parse(sessionAddress));
        this.setTimezone();
      }
    }
    this.footerService.setNavigationMode({enableNavForward: this.enableForward.bind(this),
      onNextFunction: this.saveValue.bind(this), navLinkForward: '/dates'});
  }

  public setTimezone(): void {
    const placeResult: PlaceResult|null = this.otherLocationForm.controls.location.value;
    this.timezone = null;
    if (placeResult){
      const lat = retrieveLat(placeResult.geometry);
      const lng = retrieveLng(placeResult.geometry);
      this.geolocationService.getCurrentTimeZoneDataByLatLong(lat, lng, tz => {
        this.zone.run(args => {
          if (isTimeZoneModel(tz)){
            this.timezone = tz;
            this.otherLocationForm.controls.location.updateValueAndValidity();
          }else{
            console.warn('Google returned no results for timezone on ' + placeResult.formatted_address);
            this.otherLocationForm.controls.location.updateValueAndValidity();
          }
        });
      });
    }
    }

  public saveValue(): void {
    const placeResult: PlaceResult|null = this.otherLocationForm.controls.location.value;
    if (placeResult === null || !this.timezone) {
      this.caseDataService.saveSessionData(this.KEY_LAST_SELECTED_ADDRESS, undefined);
    } else{
      const address: ModifiedPlaceResult = this.convertToModifiedPlaceResult(placeResult);

      this.caseDataService.saveSessionData(this.KEY_LAST_SELECTED_ADDRESS, JSON.stringify(address));
      const mapsAddress: MapsAddress = this.geolocationService.parseAddress(address.address_components);
      if (mapsAddress.city && mapsAddress.country && mapsAddress.alpha2Code){
        this.save(mapsAddress, address.formatted_address, retrieveLat(address.geometry), retrieveLng(address.geometry));
      }else{
        this.geolocationService.getAddressByLatLong(geoAddresses => {
            const geocoderMapsAddress = this.geolocationService.getComposedMapsAddress(geoAddresses.results);
            this.save(geocoderMapsAddress, address.formatted_address, retrieveLat(address.geometry), retrieveLng(address.geometry));
        }, retrieveLat(address.geometry), retrieveLng(address.geometry));
      }
    }
  }

  private convertToModifiedPlaceResult(address: PlaceResult): ModifiedPlaceResult {
    delete address.utc_offset;
    delete address.opening_hours;
    return address as ModifiedPlaceResult;
  }

  private save(address: MapsAddress, formattedAddress: string, lat: number, lng: number): void {
    const addressModel: AddressModel = {
      latitude: lat,
      longitude: lng,
      streetName: address.streetName,
      streetNumber: address.streetNumber,
      city: address.city,
      zipCode: address.zipCode,
      country: address.country,
      alpha2Code: address.alpha2Code,
      formattedAddress,
      addressIncident: true,
      timeZone: this.timezone
    };
    this.caseDataService.save({addressIncident: addressModel});
  }

  enableForward(): boolean {
    return this.otherLocationForm.valid;
  }

  timeZoneValidator(control: AbstractControl): ValidationErrors | null {
    return this.timezone ? null : {timezone: 'Could not get timezone for address'};
  }
}
