<div>
  <img src="brandicons/front_page_picture.png" alt=""
       class="py-4 px-0 d-flex container-fluid">

  <scu-title-header>{{ 'start.description' | translate }}</scu-title-header>

  <label class="mb-4">
    {{ 'start.text' | translate }}
  </label>
  <span *ngIf="languages.length !== 0">
    <scu-select-flag [options]="languages"
                     [formControl]="selectedCode"
                     [imgIconSizeCssClass]="'icon-l'"
                     [dataTest]="dataTestId"
                     [translatePrefix]="translatePrefix"
                     (ngModelChange)="onSelectLanguageEvent()">
    </scu-select-flag>
  </span>
</div>
