export class TimeZoneModel {

  dstOffset: number;
  rawOffset: number;
  timeZoneId: string;
  timeZoneName: string;

  constructor() {
    this.dstOffset = 0;
    this.rawOffset = 0;
    this.timeZoneId = '';
    this.timeZoneName = '';
  }
}

export function isTimeZoneModel(object: object): object is TimeZoneModel{
  const tzm = object as TimeZoneModel;
  return tzm.dstOffset !== undefined && tzm.rawOffset !== undefined && tzm.timeZoneId !== undefined && tzm.timeZoneName !== undefined;
}
