<div [formGroup]="form">
<scu-title-header>{{ 'insurance-select.title' | translate }}</scu-title-header>
<label class="text-16 lh-15 pb-4"> {{ 'insurance-select.description' | translate }} </label>

  <scu-radio-button-list
    class="border-list sos-style"
    [control]="$any(this.form).controls.customerProductType"
    [items]="customerProductTypes"
    [wrapperClasses]="'flex-value'"
    [labelClasses]="'ps-1 text-18'"
    [dataTest]="dataTestId">
    <ng-template #itemFormat let-type let-inputID="id">
      {{ 'insurance-select.' + type | translate }}
    </ng-template>
  </scu-radio-button-list>

  <div *ngIf="customerProductTypeSelected()">
    <div class="pt-4 pb-2">
      <scu-select-flag
        [options]="countries"
        formControlName="nationality"
        [imgIconSizeCssClass]="'icon-xsm'"
        [translatePrefix]="'general.companies'"
        (ngModelChange)="onNationalityChange()"
      >
      </scu-select-flag>
    </div>

    <div class="py-2">
      <ng-select *ngIf="insuranceCompanyList" #ngSelect
                 [attr.data-test]="'ng-select-insuranceCompanies'"
                 class="glasspaneBrandingClass"
                 [items]="insuranceCompanyList"
                 id="ng-select-company"
                 formControlName="insuranceCompany"
                 placeholder="{{getInsurancePlaceholder() | translate}}"
                 [searchable]="true"
                 [clearable]="false"
                 [hideSelected]="false"
                 bindLabel="customerProfileName"
                 notFoundText="{{ 'general.notFound' | translate }}"
                 (ngModelChange)="onInsuranceCompanyChange()">
        <ng-template ng-option-tmp let-item="item" let-index="index">
          {{item.customerProfileName}}
        </ng-template>
      </ng-select>
    </div>
  </div>
</div>
