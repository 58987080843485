import {Component, OnDestroy, OnInit} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import {ActivatedRoute, Router} from '@angular/router';
import {Language} from '../../data/language';
import {Subscription} from 'rxjs';
import {CasecreateService} from '../../services/casecreate.service';
import {CaseDataService} from '../../services/case-data.service';
import {CodeListService} from '../../services/code-list.service';
import {UntypedFormControl, Validators} from '@angular/forms';
import {filterLanguageToNordic, FooterService, LanguageService, SelectFlagOption, validateLanguage} from 'sos-common-ui';
import {LoggingService} from 'frontend-logging-travelcare';
import {MDC} from '../../data/mdc';
import {UrlGuardService} from '../../services/url-guard.service';
import {FlowType} from '../../shared/api/models';
import {IncidentCauseCodeEnum} from '../../data/incidentCauseCodeEnum';
import {FlowTypeEnum} from '../../data/flowTypeEnum';

@Component({
  selector: 'app-start',
  templateUrl: './start.component.html',
  styleUrls: ['./start.component.scss']
})
export class StartComponent implements OnInit, OnDestroy {
  public static readonly INSURANCE_SET_VIA_URL = 'sesKeyInsuranceSetViaUrl';
  public static readonly ONLINECARE_FLOW = 'onlinecareSetViaUrl';

  // tslint:disable-next-line:max-line-length
  languages: SelectFlagOption[] = Language.LANGUAGES.map(l => ({flagAlpha2Code: l.flagCode, text: l.code, code: l.code} as SelectFlagOption));
  selectedCode: UntypedFormControl = new UntypedFormControl('', [Validators.required]);
  languageChangeSubscription: Subscription;
  dataTestId = 'choose-country';
  translatePrefix = 'general.language.long';

  constructor(private router: Router,
              private route: ActivatedRoute,
              private translate: TranslateService,
              private caseCreateService: CasecreateService,
              private caseDataService: CaseDataService,
              private codeListService: CodeListService,
              private languageService: LanguageService,
              private footerService: FooterService,
              private loggingService: LoggingService,
              private urlGuardService: UrlGuardService) {
  }

  ngOnInit(): void {
    // tslint:disable-next-line:no-console
    console.info('/start page');

    const insuranceCode = this.route.snapshot.queryParamMap.get('insuranceCode');
    const countryCode = this.route.snapshot.queryParamMap.get('countryCode');
    const language = this.route.snapshot.queryParamMap.get('language');
    const flowtype = this.route.snapshot.queryParamMap.get('flowtype');

    if (language !== undefined && language !== null) {
      this.languageService.setApplicationLanguage(validateLanguage(language));
    }

    this.caseDataService.saveSessionData(StartComponent.ONLINECARE_FLOW, 'false');
    if (flowtype && flowtype === FlowType.OnlineCare) {
      this.caseDataService.saveSessionData(StartComponent.ONLINECARE_FLOW, 'true');
      this.caseDataService.update(caseData => {
        caseData.incidentCauseCode = IncidentCauseCodeEnum.ONLINE_CARE;
        caseData.flowType = FlowTypeEnum.ONLINE_CARE;
      });
    }

    this.caseDataService.saveSessionData(StartComponent.INSURANCE_SET_VIA_URL, 'false');
    if (insuranceCode !== undefined && insuranceCode !== null
      && countryCode !== undefined && countryCode !== null) {
      this.caseCreateService.getInsuranceCompanies(countryCode).subscribe(
        items => {
          items.forEach(insComp => {
            if (insComp.profileCode === insuranceCode && insComp.customerProductRelationBank == null) {
              this.caseDataService.update(caseData => {
                caseData.insuranceCompany = insComp;
                this.loggingService.addMDC(MDC.CPCode, insComp.profileCode);
                this.loggingService.addMDC(MDC.CPName, insComp.customerProfileName);
              });
              this.caseDataService.saveSessionData(StartComponent.INSURANCE_SET_VIA_URL, 'true');
            }
          });
        },
        error => this.errorOut(error)
      );
      this.codeListService.getPurposeOfTravelCodes(this.translate.getDefaultLang()).subscribe(
        next => {
        },
        error => this.errorOut(error));
    } else {
      this.caseCreateService.getInsuranceCompanies(
        this.languageService.getCountryCode(filterLanguageToNordic(this.translate.getDefaultLang()))
      ).subscribe(
        next => {
        },
        error => this.errorOut(error));
      this.codeListService.getPurposeOfTravelCodes(this.translate.getDefaultLang()).subscribe(
        next => {
        },
        error => this.errorOut(error));
    }
    this.selectedCode.setValue(validateLanguage(sessionStorage.getItem('language')));

    this.caseDataService.update(caseData => {
      caseData.reporter.preferredLanguage = this.selectedCode.value;
    });

    this.languageChangeSubscription = this.languageService.languageChange.subscribe(
      lang => {
        // should only try to change if there is a new lang value,
        // otherwise we will get a forever loop, where the formcontrol(selectedCode)
        // triggers angular change detection in languageService and vice versa
        if (lang !== this.selectedCode.value) {
          this.selectedCode.setValue(lang);
        }
      }
    );
    this.footerService.setEntryMode({navLinkEntry: '/insurance-select', onNextEntryFn: () => {
      this.urlGuardService.disable();
    }});
  }

  ngOnDestroy(): void {
    this.languageChangeSubscription?.unsubscribe();
  }

  onSelectLanguageEvent(): void {
    this.languageService.setApplicationLanguage(this.selectedCode.value);
    this.caseDataService.update(caseData => {
      caseData.reporter.preferredLanguage = this.selectedCode.value;
    });
  }

  private errorOut(error: any): void {
    this.router.navigate(['error']);
  }
}
