import {IncidentCauseCodeEnum} from '../../../data/incidentCauseCodeEnum';

export function incidentCauseCodeHasInquiryType(causeCode: IncidentCauseCodeEnum): boolean {
  switch (causeCode) {
    case IncidentCauseCodeEnum.MEDICAL:
    case IncidentCauseCodeEnum.ONLINE_CARE:
      return true;
    default:
      return false;
  }
}
