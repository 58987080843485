/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { caseoptionsV1CountriesLanguageGet } from '../fn/case-options-resource/caseoptions-v-1-countries-language-get';
import { CaseoptionsV1CountriesLanguageGet$Params } from '../fn/case-options-resource/caseoptions-v-1-countries-language-get';
import { caseoptionsV1InsuranceCollectionsNordicGet } from '../fn/case-options-resource/caseoptions-v-1-insurance-collections-nordic-get';
import { CaseoptionsV1InsuranceCollectionsNordicGet$Params } from '../fn/case-options-resource/caseoptions-v-1-insurance-collections-nordic-get';
import { caseoptionsV1InsuranceCountryGet } from '../fn/case-options-resource/caseoptions-v-1-insurance-country-get';
import { CaseoptionsV1InsuranceCountryGet$Params } from '../fn/case-options-resource/caseoptions-v-1-insurance-country-get';
import { caseoptionsV1PurposeOfTravelLanguageGet } from '../fn/case-options-resource/caseoptions-v-1-purpose-of-travel-language-get';
import { CaseoptionsV1PurposeOfTravelLanguageGet$Params } from '../fn/case-options-resource/caseoptions-v-1-purpose-of-travel-language-get';
import { Country } from '../models/country';
import { InsuranceCompany } from '../models/insurance-company';
import { InsuranceCompanyWithCountry } from '../models/insurance-company-with-country';
import { PurposeOfTravel } from '../models/purpose-of-travel';

@Injectable({ providedIn: 'root' })
export class CaseOptionsResourceService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `caseoptionsV1CountriesLanguageGet()` */
  static readonly CaseoptionsV1CountriesLanguageGetPath = '/caseoptions/v1/countries/{language}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `caseoptionsV1CountriesLanguageGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  caseoptionsV1CountriesLanguageGet$Response(params: CaseoptionsV1CountriesLanguageGet$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<Country>>> {
    return caseoptionsV1CountriesLanguageGet(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `caseoptionsV1CountriesLanguageGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  caseoptionsV1CountriesLanguageGet(params: CaseoptionsV1CountriesLanguageGet$Params, context?: HttpContext): Observable<Array<Country>> {
    return this.caseoptionsV1CountriesLanguageGet$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<Country>>): Array<Country> => r.body)
    );
  }

  /** Path part for operation `caseoptionsV1InsuranceCollectionsNordicGet()` */
  static readonly CaseoptionsV1InsuranceCollectionsNordicGetPath = '/caseoptions/v1/insurance/collections/nordic';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `caseoptionsV1InsuranceCollectionsNordicGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  caseoptionsV1InsuranceCollectionsNordicGet$Response(params?: CaseoptionsV1InsuranceCollectionsNordicGet$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<InsuranceCompanyWithCountry>>> {
    return caseoptionsV1InsuranceCollectionsNordicGet(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `caseoptionsV1InsuranceCollectionsNordicGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  caseoptionsV1InsuranceCollectionsNordicGet(params?: CaseoptionsV1InsuranceCollectionsNordicGet$Params, context?: HttpContext): Observable<Array<InsuranceCompanyWithCountry>> {
    return this.caseoptionsV1InsuranceCollectionsNordicGet$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<InsuranceCompanyWithCountry>>): Array<InsuranceCompanyWithCountry> => r.body)
    );
  }

  /** Path part for operation `caseoptionsV1InsuranceCountryGet()` */
  static readonly CaseoptionsV1InsuranceCountryGetPath = '/caseoptions/v1/insurance/{country}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `caseoptionsV1InsuranceCountryGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  caseoptionsV1InsuranceCountryGet$Response(params: CaseoptionsV1InsuranceCountryGet$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<InsuranceCompany>>> {
    return caseoptionsV1InsuranceCountryGet(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `caseoptionsV1InsuranceCountryGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  caseoptionsV1InsuranceCountryGet(params: CaseoptionsV1InsuranceCountryGet$Params, context?: HttpContext): Observable<Array<InsuranceCompany>> {
    return this.caseoptionsV1InsuranceCountryGet$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<InsuranceCompany>>): Array<InsuranceCompany> => r.body)
    );
  }

  /** Path part for operation `caseoptionsV1PurposeOfTravelLanguageGet()` */
  static readonly CaseoptionsV1PurposeOfTravelLanguageGetPath = '/caseoptions/v1/purpose-of-travel/{language}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `caseoptionsV1PurposeOfTravelLanguageGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  caseoptionsV1PurposeOfTravelLanguageGet$Response(params: CaseoptionsV1PurposeOfTravelLanguageGet$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<PurposeOfTravel>>> {
    return caseoptionsV1PurposeOfTravelLanguageGet(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `caseoptionsV1PurposeOfTravelLanguageGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  caseoptionsV1PurposeOfTravelLanguageGet(params: CaseoptionsV1PurposeOfTravelLanguageGet$Params, context?: HttpContext): Observable<Array<PurposeOfTravel>> {
    return this.caseoptionsV1PurposeOfTravelLanguageGet$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<PurposeOfTravel>>): Array<PurposeOfTravel> => r.body)
    );
  }

}
