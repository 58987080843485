  <scu-title-header>{{ 'option.description' | translate }}</scu-title-header>
  <scu-radio-button-list
    class="border-list d-block sos-style focus-behavior"
    [control]="incidentCause"
    [items]="incidentCauses"
    [dataTest]="dataTestId">
    <ng-template #itemFormat let-type let-inputID="id">
      <p class="text-18 mb-2">{{ 'option.' + type.toLowerCase() + '-header' | translate }}</p>
      <p class="text-16">{{ 'option.' + type.toLowerCase() + '-description' | translate }}</p>
    </ng-template>
  </scu-radio-button-list>
