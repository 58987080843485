import {Component, OnInit} from '@angular/core';
import {Router} from '@angular/router';
import {UntypedFormControl, Validators} from '@angular/forms';
import {CaseDataService} from '../../services/case-data.service';
import {FooterService} from 'sos-common-ui';

@Component({
  selector: 'app-case-description',
  templateUrl: './case-description.component.html'
})
export class CaseDescriptionComponent implements OnInit {

  textMin = 5;
  textMax = 200;

  description: UntypedFormControl;
  dataTestId = 'case-description';

  constructor(
    private router: Router,
    private casedataService: CaseDataService,
    private footerService: FooterService
  ) {
  }

  ngOnInit(): void {
    // tslint:disable-next-line:no-console
    console.info('/case-description page');
    this.footerService.setNavigationMode({
      onNextFunction: this.save.bind(this),
      navLinkForward: '/map',
      enableNavForward: this.enableForward.bind(this)
    });

    const caseData = this.casedataService.getCasedata();
    let descr = caseData.caseDescription[caseData.incidentCauseCode] === undefined ? '' : caseData.caseDescription[caseData.incidentCauseCode];

    this.description = new UntypedFormControl(
      descr,
      [
        Validators.required,
        Validators.minLength(this.textMin),
        Validators.maxLength(this.textMax)
      ]
    );
  }

  save(): void {
    this.casedataService.update(current => {
      current.caseDescription[current.incidentCauseCode] = this.description.value;
    });
  }

  enableForward(): boolean {
    return this.description.valid;
  }
}
