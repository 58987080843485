import {NgModule} from '@angular/core';
import {Routes, RouterModule} from '@angular/router';
import {StartComponent} from './components/start/start.component';
import {OptionComponent} from './components/option/option.component';
import {CaseDescriptionComponent} from './components/case-description/case-description.component';
import {InquirySelectComponent} from './components/inquiry-select/inquiry-select.component';
import {MapComponent} from './components/map/map.component';
import {OtherLocationComponent} from './components/other-location/other-location.component';
import {PolicyownerComponent} from './components/policyowner/policyowner.component';
import {DatesComponent} from './components/dates/dates.component';
import {PurposeComponent} from './components/purpose/purpose.component';
import {PersonalInfoComponent} from './components/personal-info/personal-info.component';
import {SummaryComponent} from './components/summary/summary.component';
import {ThanksComponent} from './components/thanks/thanks.component';
import {ErrorComponent} from './components/error/error.component';
import {ChildOptionComponent} from './components/child-option/child-option.component';
import {ChildInfoComponent} from './components/child-info/child-info.component';
import {PreferredChannelComponent} from './components/preferred-channel/preferred-channel.component';
import {InsuranceInMyNameComponent} from './components/insurance-in-my-name/insurance-in-my-name.component';
import { InsuranceSelectComponent } from "./components/insurance-select/insurance-select.component";

const routes: Routes = [
  {path: 'start', component: StartComponent, data: {depth: 5}},
  {path: 'insurance-select', component: InsuranceSelectComponent, data: {depth: 10}},
  {path: 'insurance-in-my-name', component: InsuranceInMyNameComponent, data: {depth: 15}},
  {path: 'policyowner', component: PolicyownerComponent, data: {depth: 20}},
  {path: 'personal-info', component: PersonalInfoComponent, data: {depth: 25}},
  {path: 'child-option', component: ChildOptionComponent, data: {depth: 30}},
  {path: 'child-info', component: ChildInfoComponent, data: {depth: 35}},

  {path: 'option', component: OptionComponent, data: {depth: 40}},
  {path: 'case-description', component: CaseDescriptionComponent, data: {depth: 45}},
  {path: 'inquiry-select', component: InquirySelectComponent, data: {depth: 50}},
  {path: 'map', component: MapComponent, data: {depth: 60}},
  {path: 'other-location', component: OtherLocationComponent, data: {depth: 70}},
  {path: 'dates', component: DatesComponent, data: {depth: 80}},
  {path: 'purpose', component: PurposeComponent, data: {depth: 85}},

  {path: 'preferred-channel', component: PreferredChannelComponent, data: {depth: 90}},
  {path: 'summary', component: SummaryComponent, data: {depth: 95}},
  {path: 'thanks', component: ThanksComponent, data: {depth: 100}},
  {path: 'error', component: ErrorComponent, data: {depth: 100}},
  {path: '', component: StartComponent, pathMatch: 'full', data: {depth: 0}},
  {path: '**', redirectTo: '/', pathMatch: 'full'}
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { enableTracing: false })],
  exports: [RouterModule]
})
export class AppRoutingModule {
}
