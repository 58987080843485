import {TimeZoneModel} from './timeZoneModel';

export class AddressModel {
  streetName: string;
  streetNumber: string;
  zipCode: string;
  city: string;
  country: string;
  alpha2Code: string;
  formattedAddress: string;
  addressIncident = false;
  latitude: number;
  longitude: number;
  timeZone: TimeZoneModel;

  constructor() {
    this.timeZone = new TimeZoneModel();
  }
}
