import {Component, OnInit} from '@angular/core';
import {Router} from '@angular/router';
import {
  FormControl, FormGroup,
  UntypedFormGroup, Validators
} from '@angular/forms';
import {CaseDataService} from '../../services/case-data.service';
import {PurposeOfTravel} from '../../data/purposeOfTravel';
import {CasedataModel} from '../../data/casedata.model';
import {CodeListService} from '../../services/code-list.service';
import {LangChangeEvent, TranslateService} from '@ngx-translate/core';
import {CheckboxLabelListComponent, CheckboxLabelListItem, FooterService} from 'sos-common-ui';

@Component({
  selector: 'app-purpose',
  templateUrl: './purpose.component.html'
})
export class PurposeComponent implements OnInit {

  purposeForm: UntypedFormGroup;
  purposesData: PurposeOfTravel[];
  checkboxItems: CheckboxLabelListItem<string>[];
  dataTestId = 'purpose-checkbox';

  constructor(private router: Router,
              private casedataService: CaseDataService,
              private codeListService: CodeListService,
              private translate: TranslateService,
              private footerService: FooterService) {

    this.purposeForm = new FormGroup({
      purposes: new FormControl<string[]>([], Validators.required)
    });

    this.fetchPurposeOfTravelList(this.translate.getDefaultLang());
  }

  ngOnInit(): void {
    // tslint:disable-next-line:no-console
    console.info('/purpose page');
    this.translate.onLangChange.subscribe((langChangeEvent: LangChangeEvent) => {
      this.fetchPurposeOfTravelList(langChangeEvent.lang);
    });
    this.footerService.setNavigationMode({
      enableNavForward: this.enableForward.bind(this),
      navLinkForward: '/summary', onNextFunction: this.save.bind(this)
    });
  }

  private initAfterDataFetch(): void {
    this.getServicePurposes();
    this.checkboxItems = this.createCheckboxLabelItems();
  }

  protected createCheckboxLabelItems(): CheckboxLabelListItem<string>[] {
    return this.purposesData.map((item, index) => {
      const id = item.businessCode;
      const labelText = item.name;
      return {id, labelText, checkboxValue: item.businessCode} as CheckboxLabelListItem<string>;
    });
  }

  save(): void {
    this.casedataService.save({travelpurpose: this.purposeForm.controls.purposes.value});
  }

  private getServicePurposes(): void {
    const caseData: CasedataModel = this.casedataService.getCasedata();
    const travelPurposes: string[] = caseData.travelpurpose;
    this.purposeForm.controls.purposes.setValue(travelPurposes !== null ? travelPurposes : []);
  }

  private fetchPurposeOfTravelList(language: string): void {
    this.purposesData = null;
    this.codeListService.getPurposeOfTravelCodes(language).subscribe(value => {
      this.purposesData = value;
      this.initAfterDataFetch();
    }, error => {
      this.router.navigate(['error']);
    });
  }

  enableForward(): boolean {
    return this.purposeForm.valid;
  }
}
