import {Injectable} from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class EnvService {
  public static readonly PROP_APP_NAME = 'MEEPOINT';

  public baseApiPath = 'api/';
  public GOOGLE_API_KEY = 'AIzaSyBXtTkBhLYRv56jYdnGGd9JCqCXuFj0LUM';
  public caseOptionsPath = this.baseApiPath + 'caseoptions/v1';
  public aimUrl = 'https://aim-api-gateway-dev1.apps.ocp.tst.sos.eu';
  public gTag = 'G-J550RJK88L';
  public blackListProfileCodeLuggage: string[] = ['CP46', 'CP255', 'CP225', 'CP257', 'CP256', 'CP14'];
  public blackListProfileCodeOther: string[] = ['CP46', 'CP255', 'CP225', 'CP257', 'CP256'];

  constructor() {
  }
}
