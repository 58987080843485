import {Component, OnDestroy, OnInit} from '@angular/core';
import {LangChangeEvent, TranslateService} from '@ngx-translate/core';
import {AbstractControl, FormControl, UntypedFormControl, UntypedFormGroup, ValidatorFn, Validators} from '@angular/forms';
import {CaseDataService} from '../../services/case-data.service';
import {Nationality} from '../../data/nationality';
import {AssistanceTypeEnum} from '../../data/assistanceTypeEnum';
import {MaskApplierService} from 'ngx-mask';
import {
  CountryEnum,
  customPatterns,
  filterLanguageToNordic,
  FooterService,
  IPhone,
  LanguageService,
  ModalOptionEnum,
  ModalPopUpComponent
} from 'sos-common-ui';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {Country} from 'src/app/data/country';
import {CodeListService} from 'src/app/services/code-list.service';
import {Subscription} from 'rxjs';
import { PersonModel } from 'src/app/data/person.model';

@Component({
  selector: 'app-personal-info',
  templateUrl: './personal-info.component.html'
})
export class PersonalInfoComponent implements OnInit, OnDestroy {

  // Set here for initial validation of nationalId
  countrySSNValidator = Nationality.getCountrySSNValidator(Nationality.byCountry(CountryEnum.DK));
  customPatterns = customPatterns;
  personalInfoForm: UntypedFormGroup;
  nationalitySupported: Nationality[] = Nationality.NATIONALITIES;
  policyowner = true;
  ssnMask: string;
  countries: Country[] = [];
  assistanceType = this.caseDataService.getCasedata().assistanceType;
  assistanceTypeEnum = AssistanceTypeEnum;
  defaultPhoneNumberCountry: CountryEnum = CountryEnum.DK;
  private fetchCountriesObservable?: Subscription;

  private dropdownOptionsNationalIdMask: string;

  constructor(
              private translate: TranslateService,
              private codeListService: CodeListService,
              private caseDataService: CaseDataService,
              private languageService: LanguageService,
              private footerService: FooterService,
              private maskService: MaskApplierService,
              private modalService: NgbModal) {
  }

  ngOnInit(): void {
    // tslint:disable-next-line:no-console
    console.info('/personal-info page');

    const languageCode: string = filterLanguageToNordic(this.translate.getDefaultLang());

    this.caseDataService.update(caseData => {
      if (caseData.reporter.mobileCountry === undefined) {
        const countryCode: string = this.languageService.getCountryCode(languageCode);
        const countryEnum = this.languageService.getCountryEnum(languageCode);
        caseData.reporter.mobileCountry = countryEnum;
        caseData.reporter.nationality = countryEnum;

        this.countrySSNValidator = Nationality.getCountrySSNValidator(Nationality.byCountry(countryEnum));
        this.dropdownOptionsNationalIdMask = countryCode;
      } else {
        this.dropdownOptionsNationalIdMask = caseData.reporter.nationality;
        this.countrySSNValidator = Nationality.getCountrySSNValidator(Nationality.byCountry(caseData.reporter.nationality));
      }

      if (caseData.reporter.nationality === undefined) {
        caseData.reporter.nationality = CountryEnum.DK;
      }

      this.personalInfoForm = new UntypedFormGroup({
        cprSelect: new UntypedFormControl(caseData.reporter.nationality, [Validators.required]),
        nationalId: new UntypedFormControl(caseData.reporter.nationalId, [Validators.required, this.countrySSNValidator, this.policyOwnerMatchValidator()]),
        firstName: new UntypedFormControl(caseData.reporter.firstname, [Validators.required]),
        surname: new UntypedFormControl(caseData.reporter.surname, [Validators.required]),
        mobile: new FormControl<IPhone>(null, [Validators.required, Validators.minLength(7)]),
        mail: new UntypedFormControl(caseData.reporter.mail, [Validators.required, Validators.minLength(6),
          Validators.pattern('^(([^<>()\\[\\]\\.,;:\\s@\\"]+(\\.[^<>()\\[\\]\\.,;:\\s@\\"]+)*)|(\\".+\\"))@(([^<>()[\\]\\.,;:\\s@\\"]+\\.)+[^<>()[\\]\\.,;:\\s@\\"]{2,})$')])
      });

      this.personalInfoForm.controls.mobile.setValue({
        prefix: caseData?.reporter?.mobilePrefix,
        number: caseData?.reporter?.mobile,
        alpha2Code: caseData?.reporter?.mobileCountry.toUpperCase() || 'DK'
      } as IPhone);

      this.fetchCountries(this.translate.getDefaultLang());

    });


    // NationalId
    this.ssnMask = Nationality.byCountry(this.dropdownOptionsNationalIdMask as CountryEnum).ssnMask;

    if (this.languageService.getCountryCode(languageCode) === CountryEnum.SE) {
      if (this.caseDataService.getCasedata().reporter.mobileCountry === undefined) {
        this.defaultPhoneNumberCountry = CountryEnum.SE;
      }
    }

    if (this.caseDataService.getCasedata().reporter.mobileCountry) {
      this.defaultPhoneNumberCountry = CountryEnum[this.caseDataService.getCasedata().reporter.mobileCountry];
    } else {
      this.defaultPhoneNumberCountry = this.languageService.getCountryEnum(languageCode);
    }
    this.footerService.setNavigationMode({
      enableNavForward: this.enableForward.bind(this), navLinkForward: this.getLink.bind(this),
      onNextFunction: this.save.bind(this)
    });

    this.translate.onLangChange.subscribe((langChangeEvent: LangChangeEvent) => {
      this.fetchCountries(langChangeEvent.lang);
    });
  }


  fetchCountries(lang: string): void{
    this.fetchCountriesObservable = this.codeListService.getCountries(lang).subscribe({
      next: countries => {
        this.countries = countries.filter((a) => (a.alpha2Code.toLocaleLowerCase() !== 'unknown'));
      },
      error: err => console.error(err)

    });

  }

  public getSSNPlaceholder(): string {
    return Nationality.byCountry(this.personalInfoForm.controls.cprSelect.value).ssnPlaceHolder;
  }

  onChangeCPRNationality(): void {
    const nationality = Nationality.byCountry(this.personalInfoForm.controls.cprSelect.value);

    this.ssnMask = Nationality.byCountry(nationality.countryCode as CountryEnum).ssnMask;
    this.personalInfoForm.controls.nationalId.setValue(
      this.maskService.applyMaskWithPattern(this.personalInfoForm.controls.nationalId.value, [this.ssnMask, customPatterns]));

    const countrySSNValidator = Nationality.getCountrySSNValidator(nationality);  // validation
    this.personalInfoForm.controls.nationalId.setValidators([Validators.required, countrySSNValidator, this.policyOwnerMatchValidator()]);
    this.personalInfoForm.controls.nationalId.updateValueAndValidity();

  }

  protected removeWhitespacesFromStr(str: string): string {
    return str.replace(/\s/g, '').trim();
  }

  save(): void {
    const phone: IPhone = this.personalInfoForm.controls.mobile.value as IPhone;
    this.caseDataService.update(caseData => {
      caseData.reporter.nationalId = this.personalInfoForm.controls.nationalId.value;
      caseData.reporter.nationality = this.personalInfoForm.controls.cprSelect.value;
      caseData.reporter.firstname = this.personalInfoForm.controls.firstName.value;
      caseData.reporter.surname = this.personalInfoForm.controls.surname.value;
      caseData.reporter.mobile = this.removeWhitespacesFromStr(phone.number);
      caseData.reporter.mobileCountry = phone.alpha2Code;
      caseData.reporter.mobilePrefix = phone.prefix;
      caseData.reporter.mail = this.personalInfoForm.controls.mail.value;
    });
  }

  enableForward(): boolean {
    return this.personalInfoForm.valid;
  }

  getLink(): string {
    return '/child-option';
  }

  assertPlusInCprText(): void {
    if (this.personalInfoForm.controls.nationalId.value.includes('+') && this.personalInfoForm.controls.cprSelect.value === CountryEnum.SE){
      this.openModal();
    }
  }

  openModal(): void{
    const nationalId = this.personalInfoForm.controls.nationalId.value as string;
    const modalRef = this.modalService.open(ModalPopUpComponent, {windowClass : 'setModalDialogSize', backdrop : 'static',
      keyboard : false, centered: true, });
    modalRef.componentInstance.headerText = this.translate.instant('personal-info.age');
    modalRef.componentInstance.approve = this.translate.instant('personal-info.yes');
    modalRef.componentInstance.cancel = this.translate.instant('personal-info.no');
    modalRef.closed.subscribe(value => {
      if (value === ModalOptionEnum.CANCEL) {
        const newNationalId = nationalId.replace('+', '-');
        this.personalInfoForm.controls.nationalId.setValue(newNationalId);
      }
    });
  }
  public policyOwnerMatchValidator(): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } | null => {
      const policyOwner: PersonModel = this.caseDataService.getCasedata().policyOwner;
      const insuranceInMyName: PersonModel = this.caseDataService.getCasedata().reporter;
      const value = control.value;

      const matchErrorMessageKey = 'personal-info.matchValueError';
      const matchValueErrorMsg: string = this.translate.instant(matchErrorMessageKey);
      
      if (value === policyOwner?.nationalId && insuranceInMyName?.policyowner === false) {
        return { MatchError: true, matchErrorMsg: matchValueErrorMsg };
      }
      return null;
    };
  }
  public shouldShowNationalIdError(): boolean {
    const nationalIdControl = this.personalInfoForm.controls.nationalId;
    return nationalIdControl.errors?.MatchError || 
           (!nationalIdControl.valid && nationalIdControl.touched);
  }

  ngOnDestroy(): void {
    this.fetchCountriesObservable?.unsubscribe();
  }
}
