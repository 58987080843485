<scu-title-header>{{ 'purpose.description' | translate }}</scu-title-header>
<label class="text-16 lh-15 align-self-start pb-4"> {{ 'purpose.text' | translate }} </label>
  <form [formGroup]="purposeForm">
    <scu-checkbox-label-list
      class="border-list sos-style"
      formControlName="purposes"
      [items]="checkboxItems"
      [checkboxClasses]="'m-0'"
      [labelClasses]="'text-18'"
      [dataTestId]="dataTestId">
      <ng-template #itemFormat let-item>
        {{item.labelText  | translate}}
      </ng-template>
    </scu-checkbox-label-list>

  </form>
