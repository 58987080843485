import {Component, EventEmitter, Input, Output} from '@angular/core';

@Component({
  selector: 'app-minimal-selector',
  templateUrl: './minimal-selector.component.html',
  styleUrls: ['./minimal-selector.component.scss']
})
/**
 * @deprecated Not in use
 */
export class MinimalSelectorComponent {

  public static NORDIC_LANGUAGES: IMinimalSelectorElement[] =
    [{value: 'da', text: 'DA'}, {value: 'en', text: 'EN'}, {value: 'fi', text: 'FI'}, {value: 'no', text: 'NO'}, {value: 'sv', text: 'SV'}];

  @Input('items') public itemsList: IMinimalSelectorElement[];
  @Input() public selectedValue: string;
  @Output() public change = new EventEmitter<IMinimalSelectorElement>();

  constructor() {}

  valueChanged(elm: IMinimalSelectorElement): void {
    this.selectedValue = elm.value;
    this.change.emit(elm);
  }

}

export interface IMinimalSelectorElement {
  value: string;
  text: string;
}
