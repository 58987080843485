import {Injectable} from '@angular/core';
import {CasedataModel} from '../data/casedata.model';

@Injectable({
  providedIn: 'root'
})
export class CaseDataService {

  private static SESS_KEY_CASE_DATA_MODEL = 'sessionKeyMeePointCaseDataModel';
  private readonly SAVED_KEYS = 'sessionStoredKeys';

  constructor() {
  }

  saveSessionData(key: string, value: string): void{
    if (key === CaseDataService.SESS_KEY_CASE_DATA_MODEL || key === this.SAVED_KEYS){
      throw new Error('Attempted to save over a reserved key');
    }
    key = key.toLowerCase();
    const keyString = sessionStorage.getItem(this.SAVED_KEYS);
    const keys: string[] = keyString !== null ? keyString.split(',') : new Array();
    if (!keys.find(possibleKey => possibleKey.toLowerCase() === key)){
      keys.push(key);
    }
    if (value === null || value === undefined){
      sessionStorage.removeItem(key);
    }else{
      sessionStorage.setItem(key, value);
    }
    sessionStorage.setItem(this.SAVED_KEYS, keys.toString());
  }

  getSessionData(key: string): string {
    const item = sessionStorage.getItem(key.toLowerCase());
    return item;
  }

  clearCaseData(): void {
    sessionStorage.removeItem(CaseDataService.SESS_KEY_CASE_DATA_MODEL);
    const keyString = sessionStorage.getItem(this.SAVED_KEYS);
    const managedKeys: string[] = keyString !== null ? keyString.split(',') : new Array();
    for (const managedKey of managedKeys){
      sessionStorage.removeItem(managedKey);
    }
    sessionStorage.removeItem(this.SAVED_KEYS);
  }

  getCasedata(): CasedataModel {
    const caseDataStr = sessionStorage.getItem(CaseDataService.SESS_KEY_CASE_DATA_MODEL);
    if (caseDataStr) {
      return JSON.parse(caseDataStr) as CasedataModel;
    }
    return new CasedataModel();
  }

  save(newData: CasedataModel): CasedataModel{
    const caseData = this.getCasedata();
    for (const key of Object.keys(newData)){
      const value = newData[key];
      if (value !== undefined){
        if (value === null){
          caseData[key] = undefined;
        }else{
          caseData[key] = value;
        }
      }

    }
    sessionStorage.setItem(CaseDataService.SESS_KEY_CASE_DATA_MODEL, JSON.stringify(caseData));
    return caseData;
  }

  update(change: (caseData: CasedataModel) => void): void{
    const currentData = this.getCasedata();
    change(currentData);
    this.save(currentData);
  }
}
