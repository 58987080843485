import { Component, Inject, LOCALE_ID, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { CaseDataService } from '../../services/case-data.service';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { EventDate } from '../../data/eventDate';
import {NgbCalendar, NgbDate, NgbDateParserFormatter, NgbDatepickerI18n, NgbDateStruct} from '@ng-bootstrap/ng-bootstrap';
import { CasedataModel } from '../../data/casedata.model';
import { CustomDateParserFormatter, DatepickerLanguage, DateChoice, DateDropdown, FooterService, LanguageService } from 'sos-common-ui';

function convertToDateObject(dateObj: { year: number, month: number, day: number }): Date {
  return new Date(dateObj.year, dateObj.month - 1, dateObj.day);
}

function dateRangeValidator(group: UntypedFormGroup): { [key: string]: any } | null {
  const departureDateObj = group.get('departureDate')?.value;
  const returnDateObj = group.get('returnDate')?.value;
  const accidentDateObj = group.get('accidentDate')?.value;

  const departureDate = departureDateObj ? convertToDateObject(departureDateObj) : null;
  const returnDate = returnDateObj ? convertToDateObject(returnDateObj) : null;
  const accidentDate = accidentDateObj ? convertToDateObject(accidentDateObj) : null;

  if (!departureDate || !returnDate || !accidentDate) {
    return null;
  }

  if (returnDate < departureDate) {
    return { returnBeforeDeparture: true };
  }

  if (accidentDate < departureDate || accidentDate > returnDate || accidentDate > today()) {
    return { accidentDateOutOfRange: true };
  }

  return null; // no error
}

const createDate = (daysOffset: number): Date => {
  const date = new Date();
  date.setDate(date.getDate() + daysOffset);
  date.setHours(0, 0, 0, 0);
  return date;
};

const yesterday = () => createDate(-1);
const today = () => createDate(0);

@Component({
  selector: 'app-dates',
  templateUrl: './dates.component.html',
  providers: [
    { provide: NgbDateParserFormatter, useClass: CustomDateParserFormatter },
    { provide: NgbDatepickerI18n, useClass: DatepickerLanguage }
  ]
})
export class DatesComponent implements OnInit {
  datesForm: UntypedFormGroup;
  eventDates: EventDate[] = EventDate.eventDates;
  dateItems: Array<DateChoice | DateDropdown>;
  dropdownChoiceAnotherDay: DateDropdown = {
    dropdownTranslationKey: 'dates.anotherDay'
  };

  maxDate: NgbDateStruct;

  constructor(
    private translate: TranslateService,
    private caseDataService: CaseDataService,
    private languageService: LanguageService,
    private footerService: FooterService,
    private calendar: NgbCalendar,
    @Inject(LOCALE_ID) public locale: string) {
  }

  ngOnInit(): void {
    this.maxDate = this.calendar.getToday();
    // tslint:disable-next-line:no-console
    console.info('/dates page');

    const caseData: CasedataModel = this.caseDataService.getCasedata();
    this.datesForm = new UntypedFormGroup({
      departureDate: new UntypedFormControl(caseData.departureDate, [Validators.required]),
      returnDate: new UntypedFormControl(caseData.returnDate, [Validators.required]),
      accidentDate: new UntypedFormControl(caseData.accidentDate, [Validators.required]),
    }, { validators: dateRangeValidator });

    this.setDateItems();

    this.footerService.setNavigationMode({
      enableNavForward: this.enableForward.bind(this),
      navLinkForward: '/purpose', onNextFunction: this.save.bind(this)
    });
  }

  private formsDateToDate(formDate: { year: number, month: number, day: number }): Date | null {
    if (!formDate) { return null; }
    const date = new Date(formDate.year, formDate.month - 1, formDate.day);
    date.setHours(0, 0, 0, 0); // set time to 00:00:00
    return date;
  }

  private get departureDate(): Date {
    return this.formsDateToDate(this.datesForm.controls.departureDate.value);
  }

  private get returnDate(): Date {
    return this.formsDateToDate(this.datesForm.controls.returnDate.value);
  }

  isDateRangeWithin(fromDate: Date, toDate: Date = fromDate): boolean {
    return (fromDate >= this.departureDate && toDate <= this.returnDate);
  }

  setDateItems(): void {
    this.dateItems = []; // start all over

    if (this.isDateRangeWithin(today())) {
      this.dateItems.push({
        translationKey: 'dates.today',
        targetDate: new NgbDate(today().getFullYear(), (today().getMonth() + 1), today().getDate())
      });
    }

    if (this.isDateRangeWithin(yesterday())) {
      this.dateItems.push({
        translationKey: 'dates.yesterday',
        targetDate: new NgbDate(yesterday().getFullYear(), (yesterday().getMonth() + 1), yesterday().getDate())
      });
    }

    if (this.departureDate < yesterday() || this.returnDate > today()) {
      this.dateItems.push(this.dropdownChoiceAnotherDay);
    }

    return;
  }

  save(): void {
    this.caseDataService.update(caseData => {
      caseData.departureDate = this.datesForm.controls.departureDate.value;
      caseData.returnDate = this.datesForm.controls.returnDate.value;
      caseData.accidentDate = this.datesForm.controls.accidentDate.value;
    });
  }

  public getPlaceholder(): string {
    return this.languageService.getDatePattern(this.translate.getDefaultLang());
  }

  enableForward(): boolean {
    return this.datesForm.valid;
  }

  public getAaxAccidentDate(): NgbDate {
    const returnDate = this.formsDateToDate(this.datesForm.controls.returnDate.value);
    const present: NgbDate = new NgbDate(today().getFullYear(), (today().getMonth() + 1), today().getDate());
    return (returnDate < today()) ? this.datesForm.controls.returnDate.value : present;
  }
}
