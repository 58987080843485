import {TranslateService} from '@ngx-translate/core';

export const translateLangs: string[] = ['da', 'sv', 'no', 'en', 'fi'];

export function appInitializerFactory(translate: TranslateService) {
  return () => {
    const loadTranslations = translateLangs.map(lang => translate.use(lang).toPromise());
    return Promise.all(loadTranslations).then(() => {
      translate.setDefaultLang('en');
    }).catch(error => {
      console.error('Error loading translations:', error);
    });
  };
}
