import {Injectable} from '@angular/core';
import Geocoder = google.maps.Geocoder;


@Injectable({
  providedIn: 'root'
})
export class GoogleGeocoderService {
  private geocoder: google.maps.Geocoder;

  constructor() {
    this.geocoder = new google.maps.Geocoder() as Geocoder;
  }


  geocode(
    request: google.maps.GeocoderRequest,
    callback?: ((a: (google.maps.GeocoderResult[] | null), b: google.maps.GeocoderStatus) => void) | null
  ): Promise<google.maps.GeocoderResponse> {
    return this.geocoder.geocode(request, callback);
  }
}
