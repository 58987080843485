<scu-title-header>{{ 'dates.description' | translate }}</scu-title-header>
  <form [formGroup]="datesForm">

    <label class="text-18 pb-2"> {{ 'dates.departureDate' | translate }} </label>
    <div class="input-group">
      <input class="form-control text-18 calendar-icon scu-secondary-faded-border sos-style"
             [attr.data-test]="'departure-date'"
             formControlName="departureDate"
             name="departureDate"
             id="departureDate"
             ngbDatepicker #departureDate="ngbDatepicker"
             [placeholder]="getPlaceholder()"
             (click)="departureDate.toggle()"
             (dateSelect)="setDateItems()"
             readonly
             [maxDate]="maxDate">
    </div>


    <label class="text-18 pt-5 pb-2"> {{ 'dates.returnDate' | translate }} </label>

    <div class="input-group">
      <input class="form-control text-18 calendar-icon scu-secondary-faded-border sos-style"
             [attr.data-test]="'return-date'"
             formControlName="returnDate"
             name="returnDate"
             id="returnDate"
             ngbDatepicker #returnDate="ngbDatepicker"
             [placeholder]="getPlaceholder()"
             (click)="returnDate.toggle()"
             (dateSelect)="setDateItems()"
             readonly
             [minDate]="this.datesForm.controls.departureDate.value">
    </div>

    <div *ngIf="this.datesForm.controls.departureDate.value && this.datesForm.controls.returnDate.value">
      <label class="text-18 pt-5 pb-2"> {{ 'dates.eventText' | translate }} </label>
      <scu-datepicker-list
        elementId="dp"
        class="sos-style d-block"
        formControlName="accidentDate"
        [items]="dateItems"
        [autoClose]="true"
        [minDate]="this.datesForm.controls.departureDate.value"
        [maxDate]="getAaxAccidentDate()"
      ></scu-datepicker-list>
      <!-- [maxDate]="this.datesForm.controls.returnDate.value" -->
    </div>
  </form>
