/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { PurposeOfTravel } from '../models/purpose-of-travel';
import { v1PurposeOfTravelLanguageGet } from '../fn/code-list-resource/v-1-purpose-of-travel-language-get';
import { V1PurposeOfTravelLanguageGet$Params } from '../fn/code-list-resource/v-1-purpose-of-travel-language-get';

@Injectable({ providedIn: 'root' })
export class CodeListResourceService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `v1PurposeOfTravelLanguageGet()` */
  static readonly V1PurposeOfTravelLanguageGetPath = '/v1/purpose-of-travel/{language}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `v1PurposeOfTravelLanguageGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  v1PurposeOfTravelLanguageGet$Response(params: V1PurposeOfTravelLanguageGet$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<PurposeOfTravel>>> {
    return v1PurposeOfTravelLanguageGet(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `v1PurposeOfTravelLanguageGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  v1PurposeOfTravelLanguageGet(params: V1PurposeOfTravelLanguageGet$Params, context?: HttpContext): Observable<Array<PurposeOfTravel>> {
    return this.v1PurposeOfTravelLanguageGet$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<PurposeOfTravel>>): Array<PurposeOfTravel> => r.body)
    );
  }

}
