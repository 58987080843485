import {CustomerProductTypeEnum} from './customerProductTypeEnum';

export class InsuranceCompany {
  customerProfileName: string;
  profileCode: string;
  customerProductType: CustomerProductTypeEnum;
  customerProductRelationId: number;
  customerProductRelationBank: string;
  profileId?: number;

  constructor(
    customerProfileName: string,
    profileCode: string,
    customerProductType: CustomerProductTypeEnum,
    customerProductRelationId: number,
    customerProductRelationBank: string,
    profileId?: number
  ) {
    this.customerProfileName = customerProfileName;
    this.profileCode = profileCode;
    this.customerProductType = customerProductType;
    this.customerProductRelationId = customerProductRelationId;
    this.customerProductRelationBank = customerProductRelationBank;
    this.profileId = profileId;
  }
}
