import {Injectable} from '@angular/core';
import {CasedataModel} from '../data/casedata.model';
import {Subscription} from 'rxjs';
import {NavigationEnd, Router} from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class UrlGuardService {

  public readonly ALLOWED_START_ROUTES = new Set(['/', '/start', '/error']);

  kickSubscription: Subscription | undefined;


  constructor(private router: Router) {
  }

  public enable(): void {
    this.kickSubscription?.unsubscribe();
    this.kickSubscription = this.router.events.subscribe(event => {
      if (event instanceof NavigationEnd){
        this.kickBadEntryPoints(event);
      }
    });
  }

  public disable(): void {
    this.kickSubscription?.unsubscribe();
  }

  private kickBadEntryPoints(event: NavigationEnd): void{
    if(!this.ALLOWED_START_ROUTES.has(this.stripQueryParams(event.url))){
        // tslint:disable-next-line:no-console
        console.info('Meepoint was started from illegal page. Routing to error');
        this.router.navigate(['error']);
    }
  }

  private stripQueryParams(stringUrl: string): string{
    if (stringUrl.includes('?')){
      return stringUrl.slice(0, stringUrl.indexOf('?'));
    }else{
      return stringUrl;
    }
  }

}
