/* tslint:disable */
/* eslint-disable */
export enum FlowType {
  CreateCase = 'CREATE_CASE',
  FindDoctor = 'FIND_DOCTOR',
  CreateCaseExpatBti = 'CREATE_CASE_EXPAT_BTI',
  FindMyDoctorExpatBti = 'FIND_MY_DOCTOR_EXPAT_BTI',
  MpaTravel = 'MPA_TRAVEL',
  OnlineCare = 'ONLINE_CARE'
}
