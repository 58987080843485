import {Injectable} from '@angular/core';
import {HttpbaseService} from './httpbase.service';
import {PurposeOfTravel} from '../data/purposeOfTravel';
import {EnvService} from './env.service';
import {Observable, of} from 'rxjs';
import {map} from 'rxjs/operators';
import { Country } from '../data/country';
import {CaseOptionsResourceService} from '../shared/api/services/case-options-resource.service';

@Injectable({
  providedIn: 'root'
})
export class CodeListService extends HttpbaseService {

  private potMap: Map<string, PurposeOfTravel[]>;
  private countriesMap: Map<string, Country[]>;

  constructor(private env: EnvService,
              private caseOptionsResourceService: CaseOptionsResourceService) {
    super();
    this.potMap = new Map<string, PurposeOfTravel[]>();
    this.countriesMap = new Map<string, Country[]>();
  }

  getPurposeOfTravelCodes(language: string): Observable<PurposeOfTravel[]> {
    if (this.potMap.has(language)) {
      return of(this.potMap.get(language));
    } else {
      return this.caseOptionsResourceService.caseoptionsV1PurposeOfTravelLanguageGet({language, application: EnvService.PROP_APP_NAME})
        .pipe(map(items => {
          this.potMap.set(language, items as PurposeOfTravel[]);
          return this.potMap.get(language);
        }));
    }
  }

  getCountries(language: string): Observable<Country[]> {
    if (this.countriesMap?.has(language)) {
      return of(this.countriesMap.get(language));
    }

    return this.caseOptionsResourceService.caseoptionsV1CountriesLanguageGet({language, application: EnvService.PROP_APP_NAME}).pipe(
      map( countryItems => {
        this.countriesMap.set(language, countryItems as Country[]);
        return this.countriesMap.get(language);
      }));
  }
}
