import PlaceGeometry = google.maps.places.PlaceGeometry;

export function retrieveLat(placeGeometry: PlaceGeometry): number {
  const lat = placeGeometry.location.lat;
  return typeof lat === 'function' ? lat() : lat;
}

export function retrieveLng(placeGeometry: PlaceGeometry): number {
  const lng = placeGeometry.location.lng;
  return typeof lng === 'function' ? lng() : lng;
}
