/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { StrictHttpResponse } from '../../strict-http-response';
import { RequestBuilder } from '../../request-builder';

import { AutocompleteStructuredFormatting } from '../../models/autocomplete-structured-formatting';
import { MatchedSubstring } from '../../models/matched-substring';
import { Term } from '../../models/term';

export interface LocationV1PlacesQueryGet$Params {
  query: string;
  sessionID?: string;
}

export function locationV1PlacesQueryGet(http: HttpClient, rootUrl: string, params: LocationV1PlacesQueryGet$Params, context?: HttpContext): Observable<StrictHttpResponse<{
'description'?: string;
'placeId'?: string;
'types'?: Array<string>;
'terms'?: Array<Term>;
'distanceMeters'?: number;
'matchedSubstrings'?: Array<MatchedSubstring>;
'structuredFormatting'?: AutocompleteStructuredFormatting;
}>> {
  const rb = new RequestBuilder(rootUrl, locationV1PlacesQueryGet.PATH, 'get');
  if (params) {
    rb.path('query', params.query, {});
    rb.header('sessionID', params.sessionID, {});
  }

  return http.request(
    rb.build({ responseType: 'json', accept: 'application/json', context })
  ).pipe(
    filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
    map((r: HttpResponse<any>) => {
      return r as StrictHttpResponse<{
      'description'?: string;
      'placeId'?: string;
      'types'?: Array<string>;
      'terms'?: Array<Term>;
      'distanceMeters'?: number;
      'matchedSubstrings'?: Array<MatchedSubstring>;
      'structuredFormatting'?: AutocompleteStructuredFormatting;
      }>;
    })
  );
}

locationV1PlacesQueryGet.PATH = '/location/v1/places/{query}';
