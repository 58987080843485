<div class="content flex-layout sos-style">
  <app-info-bar></app-info-bar>
  <scu-progress-bar class="header-progress-bar"></scu-progress-bar>
  <scu-header
    [languageSelections]="languageTypes"
    [imageSourceLogo]="'/brandicons/logo.png'"
    [showCookieButton]="false"
    [displayMenuCookieButton]="true"
    [menuIconSource]="'assets/icons/icons_dynamic.svg'"
    [menuDataTestId]="'burger-menu'"
  ></scu-header>
  <div class="main-content flex-fill">
    <router-outlet></router-outlet>
  </div>
  <scu-footer [imageSource]="'/assets/img/illustration/SOSInt_Logo2019_UrgentRed_RGB.svg'" class="main-element flex-grow-0 flex-shrink-0"></scu-footer>
</div>
