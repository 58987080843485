import {Component, OnInit} from '@angular/core';
import {AimMessage} from '../../data/aim-message';
import {TranslateService} from '@ngx-translate/core';
import {AimService} from '../../services/aim.service';
import {NavigationEnd, Router} from '@angular/router';


@Component({
  selector: 'app-info-bar',
  templateUrl: './info-bar.component.html',
  styleUrls: ['./info-bar.component.scss']
})
export class InfoBarComponent implements OnInit {
  displayInfoBox = false;
  aimMessage?: AimMessage = {};

  constructor(private translate: TranslateService,
              private aimService: AimService,
              private router: Router) {
    this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        this.displayInfoBox = (event?.url === '/' || event?.url === '/start');
      }
    });
  }

  ngOnInit(): void {
    this.aimService.fetchAimMessage(this.translate.currentLang).subscribe((value: AimMessage) => {
      this.aimMessage = value;
    });
    this.translate.onLangChange.subscribe((res) => {
      this.aimService.fetchAimMessage(this.translate.currentLang).subscribe((value: AimMessage) => {
        this.aimMessage = value;
      });
    });
  }
}
