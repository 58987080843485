import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {catchError, Observable, of} from 'rxjs';
import {AimMessage} from '../data/aim-message';
import {EnvService} from './env.service';
import {map} from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class AimService {
  public static readonly applicationName: string = 'cc-ui';
  private readonly messageType: string = 'frontpage';

  constructor(private httpClient: HttpClient, private env: EnvService) {
  }

  fetchAimMessage(lang: string): Observable<AimMessage> {
    return this.httpClient
      .get<AimMessage>(`${this.env.aimUrl}/aim/public/message/${AimService.applicationName}/${lang}/${this.messageType}`)
      .pipe(catchError((err) => {
          console.error('Could not connect to AIM', err);
          return of(undefined);
        }),
        map (aim => {
          return aim as AimMessage;
        })
      );
  }
}
