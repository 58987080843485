<scu-title-header>{{ 'personal-info.description' | translate }}</scu-title-header>

  <form [formGroup]="personalInfoForm" autocomplete="on" class="mb-4">

    <ng-select class="h-em3 flex-value sos-style"
               [items]="nationalitySupported"
               (change)="onChangeCPRNationality()"
               bindLabel="countryCode"
               bindValue="countryCode"
               [searchable]="false"
               [clearable]="false"
               hideSelected="true"
               formControlName="cprSelect">
      <ng-template ng-label-tmp let-item="item">
        <span class="icon-l fi fi-{{item.flagIcon | lowercase}}" alt=""></span>
        <span [attr.data-test]="'cpr-dropdown' | createTestId :item.countryCode">{{'general.SSN-' + item.countryCode | translate}}</span>
      </ng-template>
      <ng-template ng-option-tmp let-item="item">
        <span class="icon-l fi fi-{{item.flagIcon | lowercase}}" alt=""></span>
        <span [attr.data-test]="'cpr-dropdown' | createTestId :item.countryCode">{{'general.SSN-' + item.countryCode | translate}}</span>
      </ng-template>
    </ng-select>


    <label class="text-18 pt-4 pb-2"> {{ 'personal-info.cpr' | translate }} </label>
    <input class="form-control"
      formControlName="nationalId"
      [mask]="ssnMask"
      [placeholder]="getSSNPlaceholder() | translate"
      [patterns]="customPatterns"
      [id]="'nationalId'"
      [dropSpecialCharacters]="false"
      (focusout)="assertPlusInCprText()"
      [attr.data-test]="'cpr-input'">
      <p class="text-danger m-0" *ngIf="shouldShowNationalIdError()">
    {{personalInfoForm.controls.nationalId.errors?.matchErrorMsg ||'personal-info.missingssn' | translate}}
  </p>
    <label class="text-18 pb-2"> {{ 'general.firstname' | translate }} </label>
    <input type="text"
           id="firstname"
           [maxlength]="100"
           class="form-control text-18 sos-style"
           formControlName="firstName"
           placeholder="{{'general.firstname-placeholder' | translate}}"
           [attr.data-test]="'firstname-input'">
    <p class="text-danger m-0" *ngIf="!personalInfoForm.controls.firstName.valid &&
                      (personalInfoForm.controls.firstName.dirty || personalInfoForm.controls.firstName.touched)">
      {{'personal-info.firstname-error' | translate}}
    </p>


    <label class="text-18 pb-2"> {{ 'general.surname' | translate }} </label>
    <input type="text"
           id="surname"
           [maxlength]="100"
           class="form-control text-18 sos-style m-0"
           formControlName="surname"
           placeholder="{{'general.surname-placeholder' | translate}}"
           [attr.data-test]="'surname-input'">
    <p class="text-danger m-0" *ngIf="!personalInfoForm.controls.surname.valid &&
                    (personalInfoForm.controls.surname.dirty || personalInfoForm.controls.surname.touched)">
      {{'personal-info.surname-error' | translate}}
    </p>

    <span *ngIf="countries.length !== 0">
    <scu-phone-number
      class="p-0 m-0"
      formControlName="mobile"
      [defaultCountryCode]="defaultPhoneNumberCountry"
      [errorMsg]="'general.phone-number.phone-error' | translate"
      [countries]="countries"
      [phoneNumberPlaceholder]="'general.phone-number.phoneNumber-placeholder' | translate"
    >
    </scu-phone-number>
    </span>

    <label class="text-18 pt-4 pb-2"> {{ 'personal-info.mail' | translate }} </label>
    <input type="text"
           id="mail"
           [maxlength]="100"
           class="form-control text-18 sos-style"
           formControlName="mail"
           placeholder="{{'personal-info.mail-placeholder' | translate}}"
           [attr.data-test]="'email-input'">
    <label class="text-danger m-0" *ngIf="!personalInfoForm.controls.mail.valid &&
            (personalInfoForm.controls.mail.dirty || personalInfoForm.controls.mail.touched)">
      {{'personal-info.mail-error' | translate}}
    </label>

  </form>

