import { Component, OnInit } from '@angular/core';
import { AbstractControl, UntypedFormControl, UntypedFormGroup, ValidatorFn, Validators } from '@angular/forms';
import { Nationality } from '../../data/nationality';
import { TranslateService } from '@ngx-translate/core';
import { CaseDataService } from '../../services/case-data.service';
import { CountryEnum } from '../../data/countryEnum';
import { PersonModel } from '../../data/person.model';
import { CasedataModel } from '../../data/casedata.model';
import { MaskApplierService } from 'ngx-mask';
import { customPatterns, filterLanguageToNordic, FooterService, LanguageService } from 'sos-common-ui';

@Component({
  selector: 'app-child-info',
  templateUrl: './child-info.component.html'
})
export class ChildInfoComponent implements OnInit {

  countrySSNValidator = Nationality.getCountrySSNValidator(Nationality.byCountry(CountryEnum.DK));
  childInfoForm: UntypedFormGroup;
  nationalitySupported: Nationality[] = Nationality.NATIONALITIES;
  ssnMask: string;
  ssnPlaceHolder: string;
  customPatterns = customPatterns;

  private dropdownOptions: string;

  constructor(
    public translate: TranslateService,
    public casedataService: CaseDataService,
    private languageService: LanguageService,
    private footerService: FooterService,
    private maskService: MaskApplierService) {
  }

  ngOnInit(): void {
    // tslint:disable-next-line:no-console
    console.info('/child-info page');

    let caseData: CasedataModel = this.casedataService.getCasedata();

    if (caseData.child === undefined) {
      caseData = this.casedataService.save({ child: new PersonModel() });
    }

    if (caseData.child.nationality === undefined) {
      const languageCode: string = filterLanguageToNordic(this.translate.getDefaultLang());
      const countryCode: string = this.languageService.getCountryCode(languageCode);
      const countryEnum = this.languageService.getCountryEnum(languageCode);

      caseData.child.nationality = countryEnum;
      this.dropdownOptions = countryCode;
      this.countrySSNValidator = Nationality.getCountrySSNValidator(Nationality.byCountry(countryEnum));

    } else {
      this.dropdownOptions = caseData.child.nationality;
      this.countrySSNValidator = Nationality.getCountrySSNValidator(Nationality.byCountry(caseData.child.nationality));
    }

    this.childInfoForm = new UntypedFormGroup({
      cprSelect: new UntypedFormControl(caseData.child.nationality, [Validators.required]),
      nationalId: new UntypedFormControl(caseData.child.nationalId, [Validators.required, this.countrySSNValidator, this.policyOwnerMatchValidator()]),
      firstname: new UntypedFormControl(caseData.child.firstname, [Validators.required]),
      surname: new UntypedFormControl(caseData.child.surname, [Validators.required])
    });

    // CPR
    this.ssnMask = Nationality.byCountry(CountryEnum[this.dropdownOptions.toUpperCase()]).ssnMask;
    this.ssnPlaceHolder = Nationality.byCountry(CountryEnum[this.dropdownOptions.toUpperCase()]).ssnPlaceHolder;

    if (caseData.child.nationality === undefined) {
      const countrySSNValidator = Nationality.getCountrySSNValidator(Nationality.byCountry(CountryEnum.DK));
      this.childInfoForm.controls.nationalId.setValidators([Validators.required, this.policyOwnerMatchValidator(), countrySSNValidator]);
    }
    this.footerService.setNavigationMode({
      enableNavForward: this.enableForward.bind(this),
      navLinkForward: this.getLink.bind(this), onNextFunction: this.save.bind(this)
    });

  }

  onChangeCPRNationality(): void {
    const nationality = Nationality.byCountry(this.childInfoForm.controls.cprSelect.value);

    this.ssnMask = Nationality.byCountry(CountryEnum[nationality.countryCode]).ssnMask;
    this.childInfoForm.controls.nationalId.setValue(
      this.maskService.applyMaskWithPattern(this.childInfoForm.controls.nationalId.value, [this.ssnMask, customPatterns]));

    const countrySSNValidator = Nationality.getCountrySSNValidator(nationality);  // validation
    this.childInfoForm.controls.nationalId.setValidators([Validators.required, this.policyOwnerMatchValidator(), countrySSNValidator]);
    this.childInfoForm.controls.nationalId.updateValueAndValidity();

    this.ssnPlaceHolder = Nationality.byCountry(CountryEnum[nationality.countryCode]).ssnPlaceHolder;
  }

  save(): void {
    this.casedataService.update(current => {
      current.child.nationality = this.childInfoForm.controls.cprSelect.value;
      current.child.nationalId = this.childInfoForm.controls.nationalId.value;
      current.child.firstname = this.childInfoForm.controls.firstname.value;
      current.child.surname = this.childInfoForm.controls.surname.value;
    });
  }

  public policyOwnerMatchValidator(): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } | null => {
      const caseData = this.casedataService.getCasedata();

      const policyOwner: PersonModel = caseData.policyOwner;
      const reporter: PersonModel = caseData.reporter;
      const value = control.value;

      const matchErrorMessageKey = 'child-info.matchValueError';
      const matchValueErrorMsg: string = this.translate.instant(matchErrorMessageKey);
      
      if (reporter?.policyowner === true && value === reporter?.nationalId) {
        return { MatchError: true, matchErrorMsg: matchValueErrorMsg };     
      }
      if (reporter?.policyowner === false && value === policyOwner.nationalId) {
        return { MatchError: true, matchErrorMsg: matchValueErrorMsg };
      } 
      if (value === reporter?.nationalId) {
        return { MatchError: true, matchErrorMsg: matchValueErrorMsg };
      } 
      return null;
    };
  }

  getLink(): string {
    return '/option'
  }

  enableForward(): boolean {
    return this.childInfoForm.valid;
  }
}
