/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { Diagnosis } from '../models/diagnosis';
import { v1DiagnosesLanguageGet } from '../fn/diagnosis-resource/v-1-diagnoses-language-get';
import { V1DiagnosesLanguageGet$Params } from '../fn/diagnosis-resource/v-1-diagnoses-language-get';

@Injectable({ providedIn: 'root' })
export class DiagnosisResourceService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `v1DiagnosesLanguageGet()` */
  static readonly V1DiagnosesLanguageGetPath = '/v1/diagnoses/{language}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `v1DiagnosesLanguageGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  v1DiagnosesLanguageGet$Response(params: V1DiagnosesLanguageGet$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<Diagnosis>>> {
    return v1DiagnosesLanguageGet(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `v1DiagnosesLanguageGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  v1DiagnosesLanguageGet(params: V1DiagnosesLanguageGet$Params, context?: HttpContext): Observable<Array<Diagnosis>> {
    return this.v1DiagnosesLanguageGet$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<Diagnosis>>): Array<Diagnosis> => r.body)
    );
  }

}
