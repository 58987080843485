<scu-title-header>{{ 'policyowner.description' | translate }}</scu-title-header>

<label class="text-16 lh-15 pb-5"> {{ 'policyowner.text' | translate }} </label>

  <form [formGroup]="policyOwnerForm" autocomplete="on">
    <ng-select class="h-em3 flex-value sos-style"
               [items]="nationalitySupported"
               (change)="onChangeCPRNationality()"
               bindLabel="countryCode"
               bindValue="countryCode"
               [searchable]="false"
               [clearable]="false"
               hideSelected="true"
               formControlName="cprSelect">

      <ng-template ng-label-tmp let-item="item">
        <span class="icon-l fi fi-{{item.flagIcon | lowercase}}" alt=""></span>
        <span [attr.data-test]="'cpr-dropdown' | createTestId :item.countryCode">{{'general.SSN-' + item.countryCode | translate}}</span>
      </ng-template>
      <ng-template ng-option-tmp let-item="item">
        <span class="icon-l fi fi-{{item.flagIcon | lowercase}}" alt=""></span>
        <span [attr.data-test]="'cpr-dropdown' | createTestId :item.countryCode">{{'general.SSN-' + item.countryCode | translate}}</span>
      </ng-template>
    </ng-select>


    <label class="text-18 pt-4 pb-1 d-block"> {{ 'personal-info.cpr' | translate }} </label>
    <input type="text"
           id="nationalId"
           class="form-control text-18 sos-style"
           formControlName="nationalId"
           [dropSpecialCharacters]="false"
           [mask]="ssnMask"
           [patterns]="customPatterns"
           [placeholder]="getSSNPlaceholder() | translate"
           [attr.data-test]="'cpr-input'">

    <span class="text-danger" *ngIf="policyOwnerForm.controls['nationalId'].errors !== null &&
          (policyOwnerForm.controls['nationalId'].touched)">
      {{'personal-info.missingssn' | translate}}
    </span>


    <label class="text-18 pb-1 d-block"> {{ 'general.firstname' | translate }} </label>
    <input type="text" id="firstname"
           [maxlength]="100"
           class="form-control text-18 sos-style"
           formControlName="firstname"
           placeholder="{{'general.firstname-placeholder' | translate}}"
           [attr.data-test]="'firstname-input'">
    <span class="text-danger" *ngIf="!policyOwnerForm.controls.firstname.valid &&
          (policyOwnerForm.controls.firstname.dirty || policyOwnerForm.controls.firstname.touched)">
      {{'personal-info.firstname-error' | translate}}
    </span>


    <label class="text-18 pb-1 d-block"> {{ 'general.surname' | translate }} </label>
    <input type="text"
           id="surname"
           [maxlength]="100"
           class="form-control text-18 sos-style m-0"
           formControlName="surname"
           placeholder="{{'general.surname-placeholder' | translate}}"
           [attr.data-test]="'surname-input'">
    <span class="text-danger" *ngIf="!policyOwnerForm.controls.surname.valid &&
          (policyOwnerForm.controls.surname.dirty || policyOwnerForm.controls.surname.touched)">
      {{'personal-info.surname-error' | translate}}
    </span>
    <span *ngIf="countries.length !== 0">
      <scu-phone-number
      formControlName="mobile"
      [defaultCountryCode]="defaultPhoneNumberCountry"
      [errorMsg]="'general.phone-number.phone-error' | translate"
      [countries]="countries"
      [phoneNumberPlaceholder]="'general.phone-number.phoneNumber-placeholder' | translate"
      >
      </scu-phone-number>
    </span>
  </form>
