export enum CountryEnum {
  DK = 'DK',
  NO = 'NO',
  NB = 'NO',
  NN = 'NO',
  SE = 'SE',
  FI = 'FI',
  EN = 'EN',
  GB = 'GB',
  IS = 'IS',
  FO = 'FO'
}
