import {LanguageEnum} from './languageEnum';
import {CountryEnum} from './countryEnum';

export class Language {

  public static readonly LANGUAGES: Language[] = [
    new Language(LanguageEnum.DA, 'Dansk', CountryEnum.DK, CountryEnum.DK),
    new Language(LanguageEnum.NO, 'Norsk', CountryEnum.NO, CountryEnum.NO),
    new Language(LanguageEnum.SV, 'Svenska', CountryEnum.SE, CountryEnum.SE),
    new Language(LanguageEnum.FI, 'Suomi', CountryEnum.FI, CountryEnum.FI),
    new Language(LanguageEnum.EN, 'English', CountryEnum.EN, CountryEnum.GB)
  ];
  code: LanguageEnum;
  name: string;
  countryCode: CountryEnum;
  flagCode: CountryEnum;

  constructor(code: LanguageEnum, name: string, countryCode: CountryEnum, flagCode: CountryEnum) {
    this.code = code;
    this.name = name;
    this.countryCode = countryCode;
    this.flagCode = flagCode;
  }
}
