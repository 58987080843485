/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { Question } from '../models/question';
import { v1QuestionInsuranceCountryCodeLanguageGet } from '../fn/questionnaire-resource/v-1-question-insurance-country-code-language-get';
import { V1QuestionInsuranceCountryCodeLanguageGet$Params } from '../fn/questionnaire-resource/v-1-question-insurance-country-code-language-get';

@Injectable({ providedIn: 'root' })
export class QuestionnaireResourceService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `v1QuestionInsuranceCountryCodeLanguageGet()` */
  static readonly V1QuestionInsuranceCountryCodeLanguageGetPath = '/v1/question/{insuranceCountryCode}/{language}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `v1QuestionInsuranceCountryCodeLanguageGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  v1QuestionInsuranceCountryCodeLanguageGet$Response(params: V1QuestionInsuranceCountryCodeLanguageGet$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<Question>>> {
    return v1QuestionInsuranceCountryCodeLanguageGet(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `v1QuestionInsuranceCountryCodeLanguageGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  v1QuestionInsuranceCountryCodeLanguageGet(params: V1QuestionInsuranceCountryCodeLanguageGet$Params, context?: HttpContext): Observable<Array<Question>> {
    return this.v1QuestionInsuranceCountryCodeLanguageGet$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<Question>>): Array<Question> => r.body)
    );
  }

}
