import { Injectable } from '@angular/core';
import { Diagnosis} from '../shared/api/models/diagnosis';
import { DiagnosisResourceService} from '../shared/api/services/diagnosis-resource.service';
import { Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';
import { EnvService} from './env.service';

@Injectable({
  providedIn: 'root'
})
export class DiagnosesService {
  private diagnosesMap: Map<string, Diagnosis[]>;

  constructor(private diagnosesService: DiagnosisResourceService) {
    this.diagnosesMap = new Map<string, Diagnosis[]>();
  }

  getDiagnoses(language: string): Observable<Diagnosis[]> {
    if (this.diagnosesMap.has(language)){
      return of(this.diagnosesMap.get(language));
    }
    return this.diagnosesService.v1DiagnosesLanguageGet({language, application: EnvService.PROP_APP_NAME})
      .pipe(
        map((items) => {
          this.diagnosesMap.set(language, items);
          return this.diagnosesMap.get(language);
        })
      );
  }

}
