import {Component, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {HttpbaseService} from '../../services/httpbase.service';
import {UntypedFormControl, UntypedFormGroup, Validators} from '@angular/forms';
import {CaseDataService} from '../../services/case-data.service';
import {TranslateService} from '@ngx-translate/core';
import {PreferredChannelEnum} from '../../data/preferredChannelEnum';

@Component({
  selector: 'app-preferred-channel',
  templateUrl: './preferred-channel.component.html'
})
export class PreferredChannelComponent implements OnInit {
  preferredChannelEnum: typeof PreferredChannelEnum = PreferredChannelEnum;

  preferredChannelForm = new UntypedFormGroup({
    preferredOptionType: new UntypedFormControl(this.casedataService.getCasedata().reporter.preferredChannel, [Validators.required])
  });

  constructor(private router: Router,
              private route: ActivatedRoute,
              private httpbaseService: HttpbaseService,
              private translateService: TranslateService,
              private casedataService: CaseDataService) {

  }

  ngOnInit(): void {
    // tslint:disable-next-line:no-console
    console.info('/preferred-channel page');
  }

  next(): void {
    this.casedataService.update(caseData => {
      caseData.reporter.preferredChannel = this.preferredChannelForm.controls.preferredOptionType.value;
    });

    this.router.navigate(['/summary']);
  }
}
