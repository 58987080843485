<scu-title-header>{{ 'preferred-channel.description' | translate }}</scu-title-header>>
  <form [formGroup]="preferredChannelForm" (ngSubmit)="next()">
    <div class="container">
      <div class="row mb-4">
        <div class="col">
          <input id='textmessage'
                 type="radio"
                 formControlName="preferredOptionType"
                 class="form-check-input"
                 value='{{preferredChannelEnum.TEXT_MESSAGE}}'>
        </div>
        <div class="col-10">
          <label class="grey-xlarge" for='textmessage'><b> {{ 'preferred-channel.textmessage' | translate }}</b>
            <label class="mt-2"> {{'preferred-channel.textmessageText' | translate}} </label>
          </label>
        </div>
      </div>
      <div class="row mb-4">
        <div class="col">
          <input id='phone'
                 type="radio"
                 formControlName="preferredOptionType"
                 class="form-check-input"
                 value='{{preferredChannelEnum.PHONE}}'>
        </div>
        <div class="col-10">
          <label class="grey-xlarge" for='phone'><b> {{ 'preferred-channel.phone' | translate }}</b> <br>
            <label class="grey-medium mt-2"> {{'preferred-channel.phoneText' | translate}}</label>
          </label>
        </div>
      </div>
      <div class="row mb-4">
        <div class="col">
          <input id='email'
                 type="radio"
                 formControlName="preferredOptionType"
                 class="form-check-input"
                 value='{{preferredChannelEnum.EMAIL}}'>
        </div>
        <div class="col-10">
          <label class="grey-xlarge" for='email'><b> {{ 'preferred-channel.email' | translate }}</b>
            <label class="grey-medium mt-2"> {{'preferred-channel.emailText' | translate}} </label>
          </label>
        </div>
      </div>
    </div>
    <div class="d-flex justify-content-center mb-4">
      <button type="button"
              id="next"
              [disabled]="!preferredChannelForm.valid"
              (click)="next();"
              class="btn heavy-shadow float text-xlarge align-self-center">{{ 'navigation.next' | translate }}
      </button>
    </div>
  </form>
