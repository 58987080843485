import {CountryEnum} from './countryEnum';

export class InsuranceCountry {

  public static readonly COUNTRIES: InsuranceCountry[] = [
    new InsuranceCountry(CountryEnum.DK, 'dk', CountryEnum.DK),
    new InsuranceCountry(CountryEnum.NO, 'no', CountryEnum.NO),
    new InsuranceCountry(CountryEnum.SE, 'se', CountryEnum.SE),
    new InsuranceCountry(CountryEnum.FI, 'fi', CountryEnum.FI),
    new InsuranceCountry(CountryEnum.IS, 'is', CountryEnum.IS),
    new InsuranceCountry(CountryEnum.FO, 'fo', CountryEnum.FO)
  ];
  countryCode: CountryEnum;
  name: string;
  flagCode: CountryEnum;

  constructor(countryCode: CountryEnum, name: string, flagCode: CountryEnum) {
    this.countryCode = countryCode;
    this.name = name;
    this.flagCode = flagCode;
  }
}
