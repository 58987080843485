<scu-title-header>{{ 'insurance-in-my-name.description' | translate }}</scu-title-header>

  <form [formGroup]="insuranceInMyName">
    <scu-radio-button-list
      class="border-list rounded sos-style"
      (clickAction)="save($event)"
      [control]="insuranceInMyName"
      [items]="options"
      [wrapperClasses]="'flex-value'"
      [labelClasses]="'text-18'"
      [dataTest]="dataTestId">
      <ng-template #itemFormat let-type let-inputID="id">
        {{ 'general.' + type | translate }}
      </ng-template>
    </scu-radio-button-list>
  </form>
