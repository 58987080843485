import {Component, HostBinding, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {TranslateService} from '@ngx-translate/core';
import {GeolocationService} from '../../services/geolocation.service';
import {CaseDataService} from '../../services/case-data.service';
import {AddressModel} from '../../data/address.model';
import {EnvService} from '../../services/env.service';
import {CasedataModel} from '../../data/casedata.model';
import {tap} from 'rxjs';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {MapsAddress} from '../../data/maps-address';
import {CheckboxLabelListItem, FooterService} from 'sos-common-ui';
import PlaceResult = google.maps.places.PlaceResult;
import {isTimeZoneModel, TimeZoneModel} from '../../data/timeZoneModel';

@Component({
  selector: 'app-map',
  templateUrl: './map.component.html',
  styleUrls: ['./map.component.scss']
})
export class MapComponent implements OnInit {
  mapsGroup = new FormGroup({
    sameLocationChecked: new FormControl<string[]>(['notSamePlace']),
    address: new FormControl<PlaceResult>(null, [Validators.required])
  });
  latitude = 0.0;
  longitude = 0.0;
  checkboxItem = [{ labelText: 'map.someLocation', checkboxValue: 'notSamePlace'}] as CheckboxLabelListItem<string>[];
  dataTestId = 'map-address';
  dataTestIdOtherAddressCheckbox = 'map-other-address-checkbox';

  constructor(private router: Router,
              private route: ActivatedRoute,
              private translate: TranslateService,
              private geolocationService: GeolocationService,
              private envService: EnvService,
              private caseDataService: CaseDataService,
              private footerService: FooterService) {
  }

  @HostBinding('class') role = 'position-relative';

  ngOnInit(): void {
    // tslint:disable-next-line:no-console
    console.info('/map page');
    const caseData: CasedataModel = this.caseDataService.getCasedata();
    if (caseData.addressCurrent.latitude && caseData.addressCurrent.longitude) {
      this.setAddressByLatLng(caseData.addressCurrent.latitude, caseData.addressCurrent.longitude);
    } else {
      this.setCurrentAddress();
    }
    if (caseData.addressIncident.addressIncident){
      this.mapsGroup.controls.sameLocationChecked.setValue([]);

    }
    this.footerService.setNavigationMode({enableNavForward: this.enableForward.bind(this), navLinkForward: this.getLink.bind(this)});
  }

  addressChange(): void {
    const address = this.mapsGroup.controls.address.value;
    if (address) {
      const addressLat = address.geometry.location.lat();
      const addressLng = this.longitude = address.geometry.location.lng();
      this.geolocationService.getCurrentTimeZoneDataByLatLong(addressLat, addressLng, tz => {
        if (isTimeZoneModel(tz)){
          this.latitude = addressLat;
          this.longitude = addressLng;
          this.save([{address_components: address.address_components, formatted_address: address.formatted_address}], tz);
        }else{
          console.warn('Google returned no results for timezone on ' + this.latitude + ' ' + this.longitude);
        }
      });
    }else{
      this.save( [{address_components: [], formatted_address: ''}]);
    }
  }

  onMapClick(event: google.maps.MapMouseEvent): void {
    const eventLat = event.latLng.lat();
    const eventLong = event.latLng.lng();
    this.geolocationService.getCurrentTimeZoneDataByLatLong(eventLat, eventLong, tz => {
      if (isTimeZoneModel(tz)){
        this.setAddressByLatLng(eventLat, eventLong);
      }else{
        console.warn('Google returned no results for timezone on ' + this.latitude + ' ' + this.longitude);
      }
    });

  }

  whereAmI(): void {
    this.setCurrentAddress();
  }


  public isSameLocation(): boolean {
    return this.mapsGroup.controls.sameLocationChecked.value.includes('notSamePlace');
  }

  private setAddressByLatLng(lat: number, lng: number): void {
    this.geolocationService.getAddressByLatLong(resp => {
      // This causes the addressChange listener to be fired, thus saving the value
      this.mapsGroup.controls.address.setValue(this.geolocationService.geoLocationToPlace(resp.results[0]));
    }, lat, lng);
  }

  private save(
    addresses: {address_components: google.maps.GeocoderAddressComponent[], formatted_address: string}[], timezone?: TimeZoneModel
  ): void {
        const mapsAddress: MapsAddress = this.geolocationService.getComposedMapsAddress(addresses);
        const addressModel = new AddressModel();
        addressModel.streetName = mapsAddress.streetName;
        addressModel.streetNumber = mapsAddress.streetNumber;
        addressModel.city = mapsAddress.city;
        addressModel.zipCode = mapsAddress.zipCode;
        addressModel.country = mapsAddress.country;
        addressModel.alpha2Code = mapsAddress.alpha2Code;
        addressModel.longitude = this.longitude;
        addressModel.latitude = this.latitude;
        addressModel.formattedAddress = addresses[0].formatted_address;
        addressModel.timeZone = timezone;
        this.caseDataService.update(caseData => {
          caseData.addressCurrent = addressModel;
        });
  }

  private setCurrentAddress(): void {
    this.geolocationService.locations.pipe(
      tap(data => {
        const latitude = Number(data.lat);
        const longitude = Number(data.long);
        this.setAddressByLatLng(latitude, longitude);
      }),
    ).subscribe();
  }

  getLink(): string {
    return this.isSameLocation() ? '/dates' : '/other-location';
  }

  enableForward(): boolean {
    return this.mapsGroup.controls.address.valid;
  }


}
