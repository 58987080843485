import {Component, OnInit} from '@angular/core';
import {CaseDataService} from '../../services/case-data.service';
import {UntypedFormControl, Validators} from '@angular/forms';
import {FooterService} from 'sos-common-ui';

@Component({
  selector: 'app-insurance-in-my-name',
  templateUrl: './insurance-in-my-name.component.html'
})
export class InsuranceInMyNameComponent implements OnInit {
  insuranceInMyName = new UntypedFormControl(this.casedataService.getCasedata().reporter.policyowner, [Validators.required]);
  options = ['yes', 'no'];
  dataTestId = 'insurance-in-your-name';

  constructor(public casedataService: CaseDataService,
              private footerService: FooterService) {
  }

  ngOnInit(): void {
    // tslint:disable-next-line:no-console
    console.info('/insurance-in-my-name page');
    this.setRadioButtonData();
    this.footerService.setNavigationMode({navLinkForward: this.getLink.bind(this), enableNavForward: this.getNextEnabled.bind(this)});
  }


  save(event: string): void {
    this.insuranceInMyName.setValue(event);
    this.casedataService.update(caseData => {
      if (this.insuranceInMyName.value === 'yes') {
        caseData.reporter.policyowner = true;
      } else if (this.insuranceInMyName.value === 'no') {
        caseData.reporter.policyowner = false;
      }
    });
  }

  setRadioButtonData(): void {
    const caseData = this.casedataService.getCasedata();
    if (caseData.reporter.policyowner === true) {
      this.insuranceInMyName.setValue('yes');
    } else if (caseData.reporter.policyowner === false) {
      this.insuranceInMyName.setValue('no');
    }
  }

  public isPolicyOwner(): boolean {
    return this.casedataService.getCasedata().reporter.policyowner;
  }

  public getLink(): string {
    return this.isPolicyOwner() ? '/personal-info' : '/policyowner';
  }

  public getNextEnabled(): boolean {
    return this.insuranceInMyName.valid;
  }
}
