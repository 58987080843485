/* tslint:disable */
/* eslint-disable */
import { NgModule, ModuleWithProviders, SkipSelf, Optional } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ApiConfiguration, ApiConfigurationParams } from './api-configuration';

import { MeepCaseResourceService } from './services/meep-case-resource.service';
import { CaseOptionsResourceService } from './services/case-options-resource.service';
import { MeepExpatCaseService } from './services/meep-expat-case.service';
import { PlacesResourceService } from './services/places-resource.service';
import { FrontendLoggingResourceService } from './services/frontend-logging-resource.service';
import { MfResourceService } from './services/mf-resource.service';
import { CountryResourceService } from './services/country-resource.service';
import { DiagnosisResourceService } from './services/diagnosis-resource.service';
import { InsuranceResourceService } from './services/insurance-resource.service';
import { ProfileResourceService } from './services/profile-resource.service';
import { CodeListResourceService } from './services/code-list-resource.service';
import { QuestionnaireResourceService } from './services/questionnaire-resource.service';

/**
 * Module that provides all services and configuration.
 */
@NgModule({
  imports: [],
  exports: [],
  declarations: [],
  providers: [
    MeepCaseResourceService,
    CaseOptionsResourceService,
    MeepExpatCaseService,
    PlacesResourceService,
    FrontendLoggingResourceService,
    MfResourceService,
    CountryResourceService,
    DiagnosisResourceService,
    InsuranceResourceService,
    ProfileResourceService,
    CodeListResourceService,
    QuestionnaireResourceService,
    ApiConfiguration
  ],
})
export class ApiModule {
  static forRoot(params: ApiConfigurationParams): ModuleWithProviders<ApiModule> {
    return {
      ngModule: ApiModule,
      providers: [
        {
          provide: ApiConfiguration,
          useValue: params
        }
      ]
    }
  }

  constructor( 
    @Optional() @SkipSelf() parentModule: ApiModule,
    @Optional() http: HttpClient
  ) {
    if (parentModule) {
      throw new Error('ApiModule is already loaded. Import in your base AppModule only.');
    }
    if (!http) {
      throw new Error('You need to import the HttpClientModule in your AppModule! \n' +
      'See also https://github.com/angular/angular/issues/20575');
    }
  }
}
