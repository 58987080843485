import {CountryEnum} from './countryEnum';
import {PreferredChannelEnum} from './preferredChannelEnum';

export class PersonModel {
  nationalId = '';
  nationality: CountryEnum;
  firstname = '';
  surname = '';
  mobileCountry: string;
  mobilePrefix = '';
  mobile = '';
  mail = '';
  policyowner?: boolean = undefined;
  preferredChannel: PreferredChannelEnum;
  preferredLanguage: string;

  constructor() {
  }
}
