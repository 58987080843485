import {InsuranceCompany} from './insuranceCompany';
import {AddressModel} from './address.model';
import {AssistanceTypeEnum} from './assistanceTypeEnum';
import {PersonModel} from './person.model';
import {IncidentCauseCodeEnum} from './incidentCauseCodeEnum';
import {FlowTypeEnum} from './flowTypeEnum';

export class CaseDataDto {

  assistanceType?: AssistanceTypeEnum;
  caseDescription?: string;
  flowType?: FlowTypeEnum;
  inquiryType?: string;
  insuranceCompany?: InsuranceCompany;
  travelpurpose?: string[] = new Array<string>();
  incidentCauseCode?: IncidentCauseCodeEnum;

  departureDate?: string;
  returnDate?: string;
  accidentDateRadio?: string;
  accidentDate?: string;

  addressCurrent?: AddressModel = new AddressModel();
  addressIncident?: AddressModel = new AddressModel();

  reporter?: PersonModel = new PersonModel();
  policyOwner?: PersonModel = new PersonModel();
  child?: PersonModel = undefined;

  constructor() {
  }
}
