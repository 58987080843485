<scu-title-header>{{ 'child-info.description' | translate }}</scu-title-header>
<form [formGroup]="childInfoForm" autocomplete="on">

  <ng-select class="h-em3 flex-value sos-style" [items]="nationalitySupported" (change)="onChangeCPRNationality()"
    bindLabel="countryCode" bindValue="countryCode" [searchable]="false" [clearable]="false" hideSelected="true"
    formControlName="cprSelect">
    <ng-template ng-label-tmp let-item="item">
      <span class="icon-l fi fi-{{item.flagIcon | lowercase}}" alt=""></span>
      <span [attr.data-test]="'cpr-dropdown' | createTestId :item.countryCode">{{'general.SSN-' + item.countryCode |
        translate}}</span>
    </ng-template>
    <ng-template ng-option-tmp let-item="item">
      <span class="icon-l fi fi-{{item.flagIcon | lowercase}}" alt=""></span>
      <span [attr.data-test]="'cpr-dropdown' | createTestId :item.countryCode">{{'general.SSN-' + item.countryCode |
        translate}}</span>
    </ng-template>
  </ng-select>

  <label class="text-18 pt-4 pb-1 d-block"> {{ 'personal-info.cpr' | translate }} </label>
  <input type="text" id="nationalId" class="form-control text-18 sos-style" formControlName="nationalId"
    [dropSpecialCharacters]="false" [mask]="ssnMask" [patterns]="customPatterns"
    [placeholder]="ssnPlaceHolder | translate" [attr.data-test]="'cpr-input'">
  <span class="text-danger" *ngIf="childInfoForm.controls.nationalId.errors?.MatchError || 
  !childInfoForm.controls.nationalId.valid && 
  childInfoForm.controls.nationalId.touched">
    {{childInfoForm.controls.nationalId.errors?.matchErrorMsg ||'personal-info.missingssn' | translate}}
  </span>

  <label class="text-18 pt-4 pb-1 d-block"> {{ 'general.firstname' | translate }} </label>
  <input type="text" id="firstname" [maxlength]="100" class="form-control text-18 sos-style" formControlName="firstname"
    placeholder="{{'general.firstname-placeholder' | translate}}" [attr.data-test]="'firstname'">
  <span class="text-danger" *ngIf="!childInfoForm.controls.firstname.valid && 
    childInfoForm.controls.firstname.touched">
    {{'personal-info.firstname-error' | translate}}
  </span>

  <label class="text-18 pt-4 pb-1 d-block"> {{ 'general.surname' | translate }} </label>
  <input type="text" id="lastname" [maxlength]="100" class="form-control text-18 sos-style" formControlName="surname"
    placeholder="{{'general.surname-placeholder' | translate}}" [attr.data-test]="'lastname'">
  <span class="text-danger" *ngIf="!childInfoForm.controls.surname.valid && 
    childInfoForm.controls.surname.touched
          ">
    {{'personal-info.surname-error' | translate}}
  </span>
</form>