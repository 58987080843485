/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { InsuranceCompany } from '../models/insurance-company';
import { v1InsuranceCountryGet } from '../fn/insurance-resource/v-1-insurance-country-get';
import { V1InsuranceCountryGet$Params } from '../fn/insurance-resource/v-1-insurance-country-get';

@Injectable({ providedIn: 'root' })
export class InsuranceResourceService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `v1InsuranceCountryGet()` */
  static readonly V1InsuranceCountryGetPath = '/v1/insurance/{country}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `v1InsuranceCountryGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  v1InsuranceCountryGet$Response(params: V1InsuranceCountryGet$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<InsuranceCompany>>> {
    return v1InsuranceCountryGet(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `v1InsuranceCountryGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  v1InsuranceCountryGet(params: V1InsuranceCountryGet$Params, context?: HttpContext): Observable<Array<InsuranceCompany>> {
    return this.v1InsuranceCountryGet$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<InsuranceCompany>>): Array<InsuranceCompany> => r.body)
    );
  }

}
