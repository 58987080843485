<scu-title-header>{{ 'summary.description' | translate }}</scu-title-header>

  <label class="text-16 lh-15 pb-5"> {{ 'summary.text' | translate }} </label>

  <div id="your_information" class="mb-4">
    <label class="fw-bold text-18 d-flex justify-content-between">
      {{ 'summary.personalinfoText' | translate }}

      <button type="button"
              class="btn filled-button float-end cursor-pointer py-0"
              [routerLink]="['/personal-info']"
              [attr.data-test]="'button-personal-info'">
        {{ 'summary.edit' | translate }}
      </button>
    </label>


    <label class="text-14 d-block"> {{ 'summary.cpr' | translate }} </label>
    <label class="text-18"> {{sliceSsn(caseData.reporter.nationalId, caseData.reporter.nationality)}} </label>

    <label class="text-14 pt-2 d-block"> {{ 'summary.name' | translate }} </label>
    <label class="text-18"> {{caseData.reporter.firstname}} {{caseData.reporter.surname}} </label>

    <label class="text-14 pt-2 d-block"> {{ 'summary.phone' | translate }} </label>
    <label class="text-18"> {{caseData.reporter.mobilePrefix}} - {{caseData.reporter.mobile}} </label>

    <label class="text-14 pt-2 d-block"> {{ 'summary.mail' | translate }} </label>
    <label class="text-18"> {{caseData.reporter.mail}} </label>
  </div>


  <ng-container *ngIf="caseData.assistanceType === assistanceTypeEnum.CHILD.toString() ">
    <div id="child_info" class="mb-4">
      <label class="fw-bold text-18 d-flex justify-content-between">
        {{ 'summary.childInfoText' | translate }}

        <button type="button"
                class="btn filled-button float-end cursor-pointer py-0"
                [routerLink]="['/child-info']" [attr.data-test]="'button-child-info'">
          {{ 'summary.edit' | translate }}
        </button>
      </label>

      <label class="text-14 d-block"> {{ 'summary.cpr' | translate }} </label>
      <label class="text-18"> {{sliceSsn(caseData.child.nationalId, caseData.child.nationality)}} </label>

      <label class="text-14 pt-2 d-block"> {{ 'summary.name' | translate }} </label>
      <label class="text-18">{{caseData.child.firstname}} {{caseData.child.surname}}</label>
    </div>
  </ng-container>

  <ng-container *ngIf="caseData.reporter.policyowner === false">
    <div id="policyowner" class="mb-4">
      <label class="fw-bold text-18 d-flex justify-content-between">
        {{ 'summary.policyowner' | translate }}
        <button type="button"
                class="btn filled-button float-end cursor-pointer py-0"
                [routerLink]="['/policyowner']" [attr.data-test]="'button-policyowner'">
          {{ 'summary.edit' | translate }}
        </button>
      </label>

      <label class="text-14 d-block"> {{ 'summary.cpr' | translate }} </label>
      <label
        class="text-18">{{sliceSsn(caseData.policyOwner.nationalId, caseData.policyOwner.nationality)}}</label>

      <label class="text-14 pt-2 d-block"> {{ 'summary.name' | translate }} </label>
      <label class="text-18">{{caseData.policyOwner.firstname}} {{caseData.policyOwner.surname}}</label>

      <label class="text-14 pt-2 d-block"> {{ 'summary.phone' | translate }} </label>
      <label class="text-18"> {{caseData.policyOwner.mobilePrefix}} -  {{caseData.policyOwner.mobile}} </label>
    </div>
  </ng-container>

  <div id="insurance" class="mb-4">
    <label class="fw-bold text-18 d-flex justify-content-between">
      {{ 'summary.insurance' | translate }}
      <button type="button"
              class="btn filled-button float-end cursor-pointer py-0"
              [attr.data-test]="'button-insurance-back'" [routerLink]="['/insurance-select']">
        {{ 'summary.edit' | translate }}
      </button>
    </label>

    <label class="text-14 d-block"> {{ 'summary.'+insuranceHeadlineKey | translate }} </label>
    <label class="text-18"> {{insurance}} </label>

    <label class="text-14 pt-2 d-block"> {{ 'summary.inYourName' | translate }} </label>
    <ng-template [ngIf]="caseData.reporter.policyowner" [ngIfElse]="notPolicyowner">
      <label class="text-18"> {{ 'general.yes' | translate }} </label>
    </ng-template>
    <ng-template #notPolicyowner>
      <label class="text-18"> {{ 'general.no' | translate }} </label>
    </ng-template>
  </div>

  <div id="traveldates" class="mb-4">
    <label class="fw-bold text-18 d-flex justify-content-between">
      {{ 'summary.traveldatesText' | translate }}
      <button type="button"
              class="btn filled-button float-end cursor-pointer py-0"
              [attr.data-test]="'button-dates'" [routerLink]="['/dates']">
        {{ 'summary.edit' | translate }}
      </button>
    </label>

    <label class="text-14 d-block"> {{ 'summary.departureDate' | translate }} </label>
    <label class="text-18">{{departureDate}} </label>

    <label class="text-14 pt-2 d-block"> {{ 'summary.returnDate' | translate }} </label>
    <label class="text-18">{{returnDate}} </label>
  </div>

  <div id="where_help_needed" class="mb-4">
    <label class="fw-bold text-18 d-flex justify-content-between">
      {{ 'summary.whereHelpNeededText' | translate }}
      <button type="button"
              class="btn filled-button float-end cursor-pointer py-0"
              [attr.data-test]="'button-map'" [routerLink]="['/map']">
        {{ 'summary.edit' | translate }}
      </button>
    </label>
    <label class="text-18 d-block">
      <ng-container *ngIf="this.caseData.addressCurrent.streetName">
        {{ this.caseData.addressCurrent.streetName }} {{ this.caseData.addressCurrent.streetNumber }}<br/>
      </ng-container>
      {{ this.caseData.addressCurrent.zipCode }} {{ this.caseData.addressCurrent.city ? this.caseData.addressCurrent.city : '' }}<br/>
      {{ this.caseData.addressCurrent.country }}
    </label>
  </div>

  <ng-container *ngIf="this.caseData.addressIncident.addressIncident">
    <div id="other_location" class="mb-4">
      <label class="fw-bold text-18 d-flex justify-content-between">
        {{ 'summary.incidentPlaceText' | translate }}

        <button type="button"
                class="btn filled-button float-end cursor-pointer py-0"
                [attr.data-test]="'button-other-location'" [routerLink]="['/other-location']">
          {{ 'summary.edit' | translate }}
        </button>
      </label>
      <label class="text-18 d-block">
        <ng-container *ngIf="this.caseData.addressIncident.streetName">
          {{ this.caseData.addressIncident.streetName }} {{ this.caseData.addressIncident.streetNumber }}<br/>
        </ng-container>
        {{ this.caseData.addressIncident.zipCode }} {{ this.caseData.addressIncident.city ? this.caseData.addressIncident.city : ''}}<br/>
        {{ this.caseData.addressIncident.country }}
      </label>
    </div>
  </ng-container>

  <div id="accident_info" class="mb-4">
    <label class="fw-bold text-18 d-flex justify-content-between">
      {{ 'summary.accidentText' | translate }}

      <button type="button"
              class="btn filled-button float-end cursor-pointer py-0"
              [attr.data-test]="'button-option'" [routerLink]="['/option']">
        {{ 'summary.edit' | translate }}
      </button>
    </label>

    <label class="text-18 d-block">
      {{ 'option.' + caseData.incidentCauseCode.toLowerCase() + '-header' | translate }}
    </label>

    <label class="text-14 pt-2 d-block"> {{ 'summary.accidentDescription' | translate }} </label>
    <label class="text-18">{{caseData.caseDescription[caseData.incidentCauseCode]}}</label>

    <label class="text-14 pt-2 d-block"> {{ 'summary.accidentDate' | translate }} </label>
    <label class="text-18">{{accidentDate}}</label>
  </div>

  <div id="travelpurpose" class="mb-4">
    <label class="fw-bold text-18 d-flex justify-content-between">
      {{ 'summary.travelpurposeText' | translate }}

      <button type="button"
              class="btn filled-button float-end cursor-pointer py-0"
              [attr.data-test]="'button-purpose'" [routerLink]="['/purpose']">
        {{ 'summary.edit' | translate }}
      </button>
    </label>

    <ng-container *ngIf="potMap.size > 0">
      <label *ngFor="let purpose of this.caseData.travelpurpose" class="text-18 pt-2 d-block">
        {{ potMap.get(purpose).name }}
      </label>
    </ng-container>
  </div>

  <!-- Fjern udkommentering når vi får en swagger der kan modtage preferred channel
  <label class="red text-18 d-flex justify-content-between">
    {{ 'summary.preferredChannel' | translate }}
    <span class="float-end" [routerLink]="['/preferred-channel']">
      {{ 'summary.edit' | translate }}
    </span>
  </label>

  <label class="black text-18">
    {{'summary.preferredChannel-' + this.caseDataService.getCasedata().reporter.preferredChannel | translate}}
  </label>

  <hr class="border-1 border-top border-dark">
  -->
  <div id="create_case_box" class="mb-4 sos-style">
    <label class="fw-bold text-18"> {{ 'summary.createText' | translate }} </label>
    <label class="text-16 lh-15 py-2"> {{ 'summary.createDesc' | translate }} </label>

    <div class="form-check scu-backdrop-light-bg align-self-center mt-2 p-2 d-flex rounded">
      <input type="checkbox" id="checkBoxTerms" class="form-check-input mt-1 ms-1 me-1 flex-shrink-0" (change)="onAcceptTermsClick($event)"
             [attr.data-test]="'checkbox-terms'">
      <label class="text-16 ps-2"
             for="checkBoxTerms"
             [innerHTML]="('summary.acceptTerms' | translate) + '<br/>' + ('summary.acceptTermsUrl' | translate)">
      </label>
    </div>
    <div *ngIf="isShowOnlineCareText()" class="scu-backdrop-light-bg align-self-center mt-2 p-2 d-flex flex-column rounded">

      <label class="text-16 ps-2 pb-2" [innerHTML]="('summary.onlinCareTerms' | translate)"></label>
      <label class="fw-bold text-16 ps-2" [innerHTML]="('summary.onlinCareWarning' | translate)"></label>
    </div>
  </div>
