<scu-title-header>{{ 'inquiry-select.description' | translate }}</scu-title-header>


  <label class="text-16 mb-4"> {{ 'inquiry-select.text' | translate }} </label>
  <scu-extendable-radio-list
    class="border-list d-block sos-style focus-behavior"
    [borderElementClasses]="'flex-value'"
    [items]="diagnosis"
    [control]="type"
    [dataTest]="dataTestIdRadioButton"
    valueAttr="businessKey">
    <ng-template #itemFormat let-item let-index="index">
      <p *ngIf="item" class="text-18 mt-3">{{ item.name }}</p>
    </ng-template>
    <ng-template #activeArea>
      <scu-text-area [control]="description" [maxLength]="inquiryTextMax"
                           placeholder="{{'inquiry-select.placeholder' | translate}}"
                           [error]='description.errors?.["minlength"]' [displayBorder]="false" [dataTest]="dataTestIdTextArea" rows="5">
      </scu-text-area>
    </ng-template>
  </scu-extendable-radio-list>
