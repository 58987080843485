<ng-select class="minimal-selector"
  [items]="itemsList"
  bindLabel="text"
  bindValue="value"
  [searchable]="false"
  [clearable]="false"
  hideSelected="true"
  [(ngModel)]="selectedValue"
  (change)="valueChanged($event)">
  <ng-template ng-label-tmp let-item="item">
    {{item.text}}
  </ng-template>

  <ng-template ng-option-tmp let-item="item">
    {{item.text}}
  </ng-template>

</ng-select>
