import {Component, OnInit} from '@angular/core';
import {UntypedFormControl, Validators} from '@angular/forms';
import {CaseDataService} from '../../services/case-data.service';
import {FooterService} from 'sos-common-ui';
import {DiagnosesService} from '../../services/diagnoses.service';
import {TranslateService} from '@ngx-translate/core';
import {Diagnosis} from '../../shared/api/models/diagnosis';

@Component({
  selector: 'app-inquiry-select',
  templateUrl: './inquiry-select.component.html'
})
export class InquirySelectComponent implements OnInit {
  diagnosis: Diagnosis[] = [];
  inquiryTextMin = 5;
  inquiryTextMax = 200;
  inquiryTextActualMax = 1000;
  dataTestIdRadioButton = 'cause-of-disease-option';
  dataTestIdTextArea = 'cause-of-disease-description';

  type = new UntypedFormControl(
    this.caseDataService.getCasedata().inquiryType, [Validators.required]
  );
  description: UntypedFormControl;

  constructor(
    private caseDataService: CaseDataService,
    private footerService: FooterService,
    private translate: TranslateService,
    private diagnosesService: DiagnosesService
  ) {
  }

  ngOnInit(): void {
    this.fetchDiagnoses(this.translate.getDefaultLang());

    this.translate.onLangChange.subscribe((res) => {
      this.fetchDiagnoses(res.lang);
    });

    // tslint:disable-next-line:no-console
    console.info('/inquiry-select page');
    this.footerService.setNavigationMode({
      enableNavForward: this.enableForward.bind(this), navLinkForward: '/map',
      onNextFunction: this.save.bind(this)
    });

    const caseData = this.caseDataService.getCasedata();
    let descr = caseData.caseDescription[caseData.incidentCauseCode] === undefined ? '' : caseData.caseDescription[caseData.incidentCauseCode];
    this.description = new UntypedFormControl(
      descr,
      [
        Validators.required,
        Validators.minLength(this.inquiryTextMin),
        Validators.maxLength(this.inquiryTextMax)
      ]
    );
  }

  fetchDiagnoses(lang: string): void {
    this.diagnosesService.getDiagnoses(lang).subscribe({
      next: (diagnosis) => {
        this.diagnosis = diagnosis;
      },
      error: err => console.error(err)
    });
  }

  save(): void {
    this.caseDataService.update(current => {
      const diagnosesName: string = this.diagnosis.find(x => x.businessKey === this.type.value)?.name;
      current.caseDescription[current.incidentCauseCode] = this.description.value;
      current.inquiryType = this.type.value;
      current.diagnosesName = diagnosesName;
    });
  }

  enableForward(): boolean {
    return this.description.valid && this.type.valid;
  }
}
