import {Component, OnDestroy, OnInit} from '@angular/core';
import {NavigationStart, Router} from '@angular/router';
import {Subscription} from 'rxjs';

@Component({
  selector: 'app-thanks',
  templateUrl: './thanks.component.html'
})
export class ThanksComponent implements OnInit, OnDestroy {

  routerEvents: Subscription;

  constructor(private router: Router) {
    this.routerEvents = router.events.subscribe((event: NavigationStart) => {
      if (event.navigationTrigger === 'popstate') {
        this.router.navigate(['/']);
      }
    });
  }

  ngOnInit(): void {
    // tslint:disable-next-line:no-console
    console.info('/thanks page');
  }

  ngOnDestroy(): void {
    this.routerEvents.unsubscribe();
  }
}
