import {Component, OnDestroy, OnInit} from '@angular/core';
import {LangChangeEvent, TranslateService} from '@ngx-translate/core';
import {FormControl, UntypedFormControl, UntypedFormGroup, Validators} from '@angular/forms';
import {CaseDataService} from '../../services/case-data.service';
import {Nationality} from '../../data/nationality';
import {CasedataModel} from '../../data/casedata.model';
import {MaskApplierService} from 'ngx-mask';
import {AssistanceTypeEnum} from '../../data/assistanceTypeEnum';
import {CountryEnum, customPatterns, filterLanguageToNordic, FooterService, IPhone, LanguageService} from 'sos-common-ui';
import {CodeListService} from 'src/app/services/code-list.service';
import {Country} from 'src/app/data/country';
import {Subscription} from 'rxjs';

@Component({
  selector: 'app-policyowner',
  templateUrl: './policyowner.component.html'
})
export class PolicyownerComponent implements OnInit, OnDestroy {

  // Set here for initial validation of nationalId and phonenumber
  countrySSNValidator = Nationality.getCountrySSNValidator(Nationality.byCountry(CountryEnum.DK));

  policyOwnerForm: UntypedFormGroup;
  nationalitySupported: Nationality[] = Nationality.NATIONALITIES;
  ssnMask: string;
  customPatterns = customPatterns;
  defaultPhoneNumberCountry: CountryEnum = CountryEnum.DK;
  countries: Country[] = [];
  private fetchCountriesObservable?: Subscription;

  private dropdownOptionsNationalIdMask: string;

  constructor(
              private translate: TranslateService,
              private casedataService: CaseDataService,
              private languageService: LanguageService,
              private codeListService: CodeListService,
              private footerService: FooterService,
              private maskService: MaskApplierService) {
  }

  ngOnInit(): void {
    // tslint:disable-next-line:no-console
    console.info('/policyowner page');
    const caseData: CasedataModel = this.casedataService.getCasedata();
    const languageCode: string = filterLanguageToNordic(this.translate.getDefaultLang());

    if (caseData.policyOwner.mobileCountry === undefined) {
      const countryCode: string = this.languageService.getCountryCode(languageCode);
      const countryEnum = this.languageService.getCountryEnum(languageCode);

      caseData.policyOwner.mobileCountry = countryEnum;
      caseData.policyOwner.nationality = countryEnum;
      this.countrySSNValidator = Nationality.getCountrySSNValidator(Nationality.byCountry(countryEnum));

      this.dropdownOptionsNationalIdMask = countryCode;
    } else {
      this.dropdownOptionsNationalIdMask = caseData.policyOwner.nationality;
      this.countrySSNValidator = Nationality.getCountrySSNValidator(Nationality.byCountry(caseData.policyOwner.nationality));
    }

    this.policyOwnerForm = new UntypedFormGroup({
      firstname: new UntypedFormControl(caseData.policyOwner.firstname, [Validators.required]),
      surname: new UntypedFormControl(caseData.policyOwner.surname, [Validators.required]),
      mobile: new FormControl<IPhone>(null, [Validators.required, Validators.minLength(8)]),
      nationalId: new UntypedFormControl(caseData.policyOwner.nationalId,
        [Validators.required, this.countrySSNValidator]),
      cprSelect: new UntypedFormControl(caseData.policyOwner.nationality, [Validators.required])

    });

    this.policyOwnerForm.controls.mobile.setValue({
      prefix: caseData?.policyOwner?.mobilePrefix,
      number: caseData?.policyOwner?.mobile,
      alpha2Code: caseData?.policyOwner?.mobileCountry.toUpperCase() || 'DK'
    } as IPhone);

    if (this.languageService.getCountryEnum(languageCode) === CountryEnum.SE) {
      if (this.casedataService.getCasedata().policyOwner.mobileCountry === undefined) {
        this.defaultPhoneNumberCountry = CountryEnum.SE;
      }
    }

    if (caseData.policyOwner.mobileCountry === undefined) {
      this.defaultPhoneNumberCountry = this.languageService.getCountryEnum(languageCode);
    } else {
      this.defaultPhoneNumberCountry = CountryEnum[caseData.policyOwner.mobileCountry];
    }

    // NationalId
    this.ssnMask = Nationality.byCountry(this.dropdownOptionsNationalIdMask as CountryEnum).ssnMask;
    this.footerService.setNavigationMode({
      enableNavForward: this.enableForward.bind(this),
      navLinkForward: this.getLink.bind(this), onNextFunction: this.commit.bind(this)
    });

    this.translate.onLangChange.subscribe((langEvent: LangChangeEvent) => {
      this.fetchCountries(langEvent.lang);
    });

    this.fetchCountries(this.translate.getDefaultLang());

  }

  fetchCountries(lang: string): void {
    this.fetchCountriesObservable = this.codeListService.getCountries(lang).subscribe({
      next: countries => {
        this.countries = countries.filter((a) => (a.alpha2Code.toLocaleLowerCase() !== 'unknown'));
      },
      error: err => console.error(err)

    });
  }

  public getSSNPlaceholder(): string {
    return Nationality.byCountry(this.policyOwnerForm.controls.cprSelect.value).ssnPlaceHolder;
  }

  onChangeCPRNationality(): void {
    const nationality = Nationality.byCountry(this.policyOwnerForm.controls['cprSelect'].value);

    this.ssnMask = Nationality.byCountry(nationality.countryCode as CountryEnum).ssnMask;
    this.policyOwnerForm.controls['nationalId'].setValue(
      this.maskService.applyMaskWithPattern(this.policyOwnerForm.controls['nationalId'].value, [this.ssnMask, customPatterns]));

    const countrySSNValidator = Nationality.getCountrySSNValidator(nationality);  // validation
    this.policyOwnerForm.controls['nationalId'].setValidators([Validators.required, countrySSNValidator]);
    this.policyOwnerForm.controls['nationalId'].updateValueAndValidity();

  }

  commit(): void {
    const phone: IPhone = this.policyOwnerForm.controls.mobile.value as IPhone;
    this.casedataService.update(caseData => {
      caseData.policyOwner.firstname = this.policyOwnerForm.controls.firstname.value;
      caseData.policyOwner.surname = this.policyOwnerForm.controls.surname.value;
      caseData.policyOwner.mobileCountry = phone.alpha2Code;
      caseData.policyOwner.mobilePrefix = phone.prefix;
      caseData.policyOwner.mobile = this.removeWhitespacesFromStr(phone.number);
      caseData.policyOwner.nationality = this.policyOwnerForm.controls.cprSelect.value;
      caseData.policyOwner.nationalId = this.policyOwnerForm.controls.nationalId.value;
    });
  }

  getLink(): string {
    return '/personal-info';
  }
  protected removeWhitespacesFromStr(str: string): string {
    return str.replace(/\s/g, '').trim();
  }

  public isChildSelected(): boolean {
    if (this.casedataService.getCasedata().assistanceType === AssistanceTypeEnum.CHILD) {
      return true;
    }
    return false;
  }

  enableForward(): boolean {
    return this.policyOwnerForm.valid;
  }

  ngOnDestroy(): void {
    this.fetchCountriesObservable?.unsubscribe();
  }
}
