import {Component, OnInit} from '@angular/core';
import {Router} from '@angular/router';
import {LangChangeEvent, TranslateService} from '@ngx-translate/core';
import {CaseDataService} from '../../services/case-data.service';
import {CasecreateService} from '../../services/casecreate.service';
import {HttpbaseService} from '../../services/httpbase.service';
import {EnvService} from '../../services/env.service';
import {AssistanceTypeEnum} from '../../data/assistanceTypeEnum';
import {CasedataModel} from '../../data/casedata.model';
import {CodeListService} from '../../services/code-list.service';
import {PurposeOfTravel} from '../../data/purposeOfTravel';
import {CountryEnum} from '../../data/countryEnum';
import {StartComponent} from '../start/start.component';
import {FooterService} from 'sos-common-ui';
import { FlowTypeEnum } from 'src/app/data/flowTypeEnum';


@Component({
  selector: 'app-summary',
  templateUrl: './summary.component.html',
  styleUrls: ['./summary.component.scss']
})
export class SummaryComponent implements OnInit {

  departureDate: string;
  returnDate: string;
  accidentDate: string;
  insurance: string;
  insuranceBackLink: string;
  insuranceHeadlineKey = 'insuranceText';
  readonly DELIMITER = '-';
  isAcceptTermsChecked: boolean;
  caseData: CasedataModel;
  assistanceTypeEnum: typeof AssistanceTypeEnum = AssistanceTypeEnum;
  blockNextClick: boolean;
  potMap: Map<string, PurposeOfTravel> = new Map<string, PurposeOfTravel>();

  constructor(private router: Router,
              private translate: TranslateService,
              public caseDataService: CaseDataService,
              private caseCreateService: CasecreateService,
              private httpBaseService: HttpbaseService,
              private envService: EnvService,
              private codeListService: CodeListService,
              private footerService: FooterService) {
    this.fetchPurposeOfTravelList(this.translate.getDefaultLang());
  }

  ngOnInit(): void {
    // tslint:disable-next-line:no-console
    console.info('/summary page');
    this.caseData = this.caseDataService.getCasedata();

    const dataSetViaUrl: string = this.caseDataService.getSessionData(StartComponent.INSURANCE_SET_VIA_URL);
    this.insurance = (dataSetViaUrl === 'true' || !this.caseData.creditCard) ?
      this.caseData.insuranceCompany.customerProfileName : this.caseData.creditCardCompany.customerProfileName;
    this.insuranceHeadlineKey = this.caseData.creditCard ? 'creditCardText' : 'insuranceText';
    this.departureDate = this.format(this.caseData.departureDate);
    this.returnDate = this.format(this.caseData.returnDate);
    this.accidentDate = this.format(this.caseData.accidentDate);

    this.translate.onLangChange.subscribe((langChangeEvent: LangChangeEvent) => {
      this.fetchPurposeOfTravelList(langChangeEvent.lang);
    });

    this.footerService.setSubmissionMode({enableNavBackward: true, enableSubmit: this.enableSubmit.bind(this),
      submitFunction: this.createCase.bind(this)});
  }

  private fetchPurposeOfTravelList(language: string): void {
    this.potMap.clear();
    this.codeListService.getPurposeOfTravelCodes(language).subscribe(
      pots => pots.forEach(pot => this.potMap.set(pot.businessCode, pot))
    );
  }

  createCase(): void {
    this.blockNextClick = true;
    this.caseCreateService.onSubmitCase(this.caseDataService.getCasedata()).subscribe({
      next: resp => {
      // Clear case and session storage data, but keep the chosen language.
      this.caseDataService.clearCaseData();
      const chosenLanguage = sessionStorage.getItem('language');
      sessionStorage.clear();
      sessionStorage.setItem('language', chosenLanguage);
      // tslint:disable-next-line:no-console
      console.info('/case created button');
      this.router.navigate(['/thanks']);
    }, error: error => {
      this.httpBaseService.handleError(error);
      this.router.navigate(['error']);
    }});
  }

  sliceSsn(nationalId: string, countryEnum: CountryEnum): string {
    switch (countryEnum) {
      case (CountryEnum.EN):
      case (CountryEnum.DK):
        return nationalId.slice(0, -4) + 'XXXX';
      case (CountryEnum.SE):
        return nationalId.slice(0, -4) + 'XXXX';
      case (CountryEnum.NO):
        return nationalId.slice(0, -5) + 'XXXXX';
      case (CountryEnum.FI):
        return nationalId.slice(0, -5) + 'XXXXX';
      case(CountryEnum.IS):
        return nationalId.slice(0, -4) + 'XXXX';
      default:
        return nationalId.slice(0, -12) + 'XXXXXXXXXX';
    }
  }

  onAcceptTermsClick(event): void {
    this.isAcceptTermsChecked = !!event.target.checked;
  }

  private format(date: string): string {
    const month: string = '' + Object.values(date)[1];
    const day: string = '' + Object.values(date)[2];
    // tslint:disable-next-line:no-string-literal
    return day.padStart(2, '0') + this.DELIMITER + month.padStart(2, '0') + this.DELIMITER + date['year'];
  }

  enableSubmit(): boolean {
    return this.isAcceptTermsChecked && !this.blockNextClick;
  }

  isShowOnlineCareText(): boolean{
    const currentFlowType = this.caseDataService.getCasedata().flowType;
    const currentLang = this.translate.currentLang;
    return (currentLang === 'no' || currentLang === 'nb' || currentLang === 'nn') && currentFlowType === FlowTypeEnum.ONLINE_CARE;
  }
}

