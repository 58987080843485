 <google-map
    class="flexmap"
    [options]="{
        zoom:17,
        disableDefaultUI: true,
        center: {
          lat: latitude,
          lng: longitude
        }
      }"
    (mapClick)="onMapClick($event)">
    <map-marker
      [options]="{
          draggable: false,
          opacity:0.9,
          position: {
            lat: latitude,
            lng: longitude
          }
        }">
    </map-marker>
  </google-map>

<div class="to-front d-flex flex-column align-items-center overlay">

  <div class="w-100 d-flex flex-column">
    <div class="width-restricted align-self-center">
      <form [formGroup]="mapsGroup">
        <h1 class="pt-4">{{ 'map.description' | translate }}</h1>
        <input scu-google-autocomplete class="w-100 clickable form-control scu-secondary-faded-border icon-scu-secondary mb-0" formControlName="address" (ngModelChange)="addressChange()">
      </form>
    </div>
  </div>


  <div class="width-restricted pe-none d-flex flex-column align-items-center justify-content-between flex-grow-1">
    <div class="whereAmIButton mt-1 m-md-1 align-self-end scu-backdrop-light-bg p-1 border rounded scu-secondary-faded-border">
      <a class="pe-auto clickable" (click)="whereAmI()">
        <div class="float-end scu-backdrop-light-bg p-1 clickable current-location-icon"></div>
      </a>
    </div>
    <form [formGroup]="mapsGroup" class="mt-auto mb-6 w-100">
      <scu-checkbox-label-list
        class="border rounded bg-white clickable m-0 d-block sos-style"
        [items]="checkboxItem"
        formControlName="sameLocationChecked"
        [labelClasses]="'text-18 clickable'"
        [checkboxClasses]="'ms-1 clickable'"
        [dataTestId]="dataTestIdOtherAddressCheckbox">
        <ng-template #itemFormat let-item>
          {{item.labelText | translate}}
        </ng-template>
      </scu-checkbox-label-list>
    </form>
  </div>



</div>

